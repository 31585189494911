<script>
  import { tick } from "svelte"
  import { derived, writable } from "svelte/store"
  import { ComboboxRoot, ComboboxOption, ComboboxOptions, ComboboxInput } from "@components/combobox"
  import { collectionsStore } from "@store/collections"
  import fuzzy from "@catalog/helpers/fuzzy"
  import Icon from "@components/Icon.svelte"

  export let collection
  export { className as class }

  let className = ""
  let input = null
  let keyword = writable(null)

  const filteredCollections = derived([collectionsStore, keyword], ([$collectionsStore, $keyword]) => {
    if (!$keyword) {
      return $collectionsStore.collections
    }

    return fuzzy($collectionsStore.collections, "name", $keyword)
  })

  async function handleOpen() {
    await tick()

    input?.focus({ preventScroll: true })
  }
</script>

<ComboboxRoot bind:value={collection} class="[ text-sm {className} ]" let:state let:combobox on:input>
  <button
    type="button"
    on:click|preventDefault={combobox.toggle}
    class="text-left border px-3.5 py-2 text-sm w-full flex justify-between items-center"
  >
    <span class="[ truncate max-w-4/5 ]">
      {#if collection}
        {collection.name}
      {:else}
        No collection selected
      {/if}
    </span>

    <span class:rotate-90={state.open} class="[ transition-transform origin-center block ]">
      <Icon name="arrow-right-s" weight="line" class="[ relative top-px ]" />
    </span>
  </button>

  {#if state.open}
    <div
      class="[ absolute p-2 pt-2.5 rounded-lg shadow-xl w-full bg-canvas/80 backdrop-blur rounded border mt-2 z-10 ]"
    >
      <div class="[ mb-2 ]">
        <ComboboxInput
          class="[ block w-full rounded border py-0.5 px-2 text-sm  ]"
          placeholder="Search for a collection..."
          bind:this={input}
          on:input={(event) => ($keyword = event.target.value)}
          on:mount={combobox.focus}
          on:escape={combobox.close}
        />
      </div>

      <ComboboxOptions on:close={() => ($keyword = null)} class="[ max-h-48 overflow-y-auto relative space-y-0.5 ]">
        {#each $filteredCollections as collection (collection.id)}
          <ComboboxOption key={collection.id} value={collection} let:active let:selected>
            <div
              class="[ py-1.5 px-2.5 rounded bg-opacity-50 cursor-pointer ]"
              class:bg-neutral-200={active}
              class:bg-green-200={selected && !active}
              class:bg-green-300={selected && active}
            >
              {collection.name}
            </div>
          </ComboboxOption>
        {/each}
      </ComboboxOptions>
    </div>
  {/if}
</ComboboxRoot>
