import { Bedding, Bath } from "./bed-and-bath"
import { Bedroom, Benches, DiningRoom, Entry, LivingRoom, Office } from "@layouts/categories/furniture"

import {
  CookwareAndBakeware,
  DecorativeKitchenAccessories,
  KitchenStorage,
  Tabletop,
} from "@layouts/categories/kitchen"

import {
  FirePitsAndTables,
  OutdoorAccents,
  OutdoorFurniture,
  OutdoorLighting,
  OutdoorRugsAndDoormats,
} from "@layouts/categories/outdoor"
import { CeilingLightsAndFans, TableAndFloorLamps, WallLighting } from "@layouts/categories/lighting"
import { HomeAccents, PillowsAndThrows } from "@layouts/categories/pillows-and-decor"
import { AllRugsAndPads } from "@layouts/categories/rugs"
import { FloorMirrors, Photography, WallAccents, WallArt, WallMirrors } from "@layouts/categories/wall-art-and-mirrors"
import { EndUse, Material, Pattern } from "@layouts/categories/fabric"
import { WallcoveringMaterial, WallcoveringPattern } from "@layouts/categories/wallcovering"

export interface RouteParams {
  [key: string]: string[]
}

export interface MenuItem {
  name: string
  path?: string
  routeParams?: RouteParams
  children?: MenuItem[]
}

export interface Menu {
  name: string
  path?: string
  routeParams?: RouteParams
  columns: MenuItem[][]
  classes: string
  placement?: string
}

const menus: Menu[] = [
  {
    name: "Living",
    path: "furniture.living-room",
    columns: [
      [LivingRoom, Office],
      [Entry, Benches],
    ],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Dining",
    path: "furniture.dining-room",
    columns: [[DiningRoom]],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Bedroom",
    path: "furniture.bedroom",
    columns: [[Bedroom, Bedding], [PillowsAndThrows]],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Lighting",
    path: "lighting",
    columns: [
      [CeilingLightsAndFans, WallLighting],
      [TableAndFloorLamps, OutdoorLighting],
    ],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Fabric",
    path: "fabric",
    columns: [[Pattern, Material, EndUse]],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Wallcovering",
    path: "wallcovering",
    columns: [[WallcoveringPattern, WallcoveringMaterial]],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Outdoor",
    path: "outdoor",
    columns: [
      [OutdoorFurniture, OutdoorAccents, OutdoorRugsAndDoormats],
      [FirePitsAndTables, OutdoorLighting],
    ],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Rugs",
    path: "rugs",
    columns: [[AllRugsAndPads]],
    classes: "grid-cols-[repeat(2,1fr)]",
  },
  {
    name: "Pillows & Accessories",
    routeParams: {
      categories: ["pillows-decor.pillows", "pillows-decor.home-accents"],
    },
    columns: [[PillowsAndThrows, HomeAccents]],
    classes: "grid-cols-[repeat(2,1fr)]",
    placement: "right",
  },
  {
    name: "Art & Mirrors",
    path: "wall-art-mirrors",
    columns: [[WallArt, WallAccents, Photography, WallMirrors, FloorMirrors]],
    classes: "grid-cols-[repeat(2,1fr)]",
    placement: "right",
  },
  {
    name: "Kitchen & Bath",
    routeParams: {
      categories: ["kitchen", "bed-bath.bath"],
    },
    columns: [[Tabletop], [CookwareAndBakeware, KitchenStorage, DecorativeKitchenAccessories, Bath]],
    classes: "grid-cols-[repeat(2,1fr)]",
    placement: "right",
  },
]

export default menus
