<script context="module">
  const qrSize = 2000
  const qrRectPos = qrSize * 0.42
  const qrRectSize = qrSize * 0.16
</script>

<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import QRCode from "qrcode"
  import { router, useForm } from "@inertiajs/svelte"
  import Analytics from "@services/Analytics"
  import notifications from "@store/notifications"
  import SubmitButton from "@components/SubmitButton.svelte"
  import CopyToClipboard from "@components/CopyToClipboard.svelte"
  import Button from "@components/Button.svelte"
  import Icon from "@components/Icon.svelte"

  export let collection

  const form = useForm({
    emails: "",
  })

  const destinations = {
    proposal: {
      name: "proposal",
      url: route("shop.collections.proposals.show", [collection.account, collection.id, collection.slug]),
      description:
        "Use this link to share your collection as a proposal to your client. They will be able to view the products and their quantities at retail price plus shipping, and add all items to their cart for checkout.",
      email: {
        subject: `${collection.account.name} has shared ${collection.name} product collection with you`,
        body: `
          ${collection.account.name} has shared a product collection with you entitled "${
          collection.name
        }". From this unique link, you can view and purchase products that have been curated by your designer. Click or copy the link below into your browser to view the collection.
          %0D%0A%0D%0A${route("shop.collections.proposals.show", [collection.account, collection.id, collection.slug])}
        `.trim(),
      },
    },
    shop: {
      name: "shop",
      url: route("shop.collections.show", [collection.account, collection.id, collection.slug]),
      description:
        "Use this link anywhere you are sharing your collection to your audience. They will be able to see the products at retail price plus shipping, and they will be able add all items to their cart to checkout.",
      email: {
        subject: `${collection.account.name} has shared ${collection.name} product collection with you`,
        body: `
          ${collection.account.name} has shared a product collection with you entitled "${
          collection.name
        }". From this unique link, you can view and purchase products that have been curated by your designer. Click or copy the link below into your browser to view the collection.
          %0D%0A%0D%0A${route("shop.collections.show", [collection.account, collection.id, collection.slug])}
        `.trim(),
      },
    },
  }

  let selectedDestination = destinations.proposal.name

  const handleCopy = () => {
    maybeMakeCollectionPublic()

    Analytics.track("Merchant copied collection url", {
      collectionId: collection.id,
    })
  }

  const handleSubmitEmail = () => {
    maybeMakeCollectionPublic()

    const { subject, body } = destinations[selectedDestination].email

    window.location.href = `mailto:${$form.emails}?subject=${subject}&body=${body}`

    Analytics.track("Merchant shared estimate url via email", {
      collectionId: collection.id,
    })
  }

  const downloadQr = () => {
    maybeMakeCollectionPublic()

    const canvas = document.createElement("canvas")

    const qrUrl = new URL(destinations[selectedDestination].url)

    qrUrl.searchParams.set("utm_source", "designer")
    qrUrl.searchParams.set("utm_medium", "qr")

    QRCode.toCanvas(
      canvas,
      qrUrl.toString(),
      {
        width: qrSize,
        color: {
          dark: "#482846",
        },
      },
      (err) => {
        if (err) {
          notifications.error({
            message: `Something went wrong generating your QR code, please try again later. (${err})`,
          })

          throw err
        }

        const ctx = canvas.getContext("2d")

        // Draw white rect over center
        ctx.fillStyle = "#fff"
        ctx.fillRect(qrRectPos, qrRectPos, qrRectSize, qrRectSize)

        // Load image
        const img = document.createElement("img")
        img.addEventListener("load", () => {
          // Draw image
          ctx.drawImage(img, qrRectPos, qrRectPos, qrRectSize, qrRectSize)

          // Save to device
          const url = canvas.toDataURL("image/png")
          const anchor = document.createElement("a")
          anchor.href = url
          anchor.download = `sd-collection-${collection.id}-qr.png`
          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)
        })
        img.src = "/images/logos/key.png"
      },
    )
  }

  const maybeMakeCollectionPublic = async () => {
    if (collection.is_public) {
      return
    }

    try {
      await axios.post(route("merchant.collections.publication.store", collection))

      router.reload()
    } catch (err) {
      notifications.error({
        message: `"${collection.name}" could not be made public, please try again.`,
      })

      throw err
    }
  }
</script>

<div class="bg-beige-50">
  <form class="grid grid-cols-2 p-4 gap-4">
    {#each Object.entries(destinations) as [_, destination]}
      <label
        class="flex items-center justify-center uppercase p-4 cursor-pointer [&:has(:focus-visible)]:ring-inset [&:has(:focus-visible)]:ring-2"
        class:bg-beige-100={selectedDestination !== destination.name}
        class:bg-beige-200={selectedDestination === destination.name}
      >
        <input
          class="sr-only"
          type="radio"
          bind:group={selectedDestination}
          value={destination.name}
          checked={selectedDestination === destination.name}
        />
        <span class="text-lg"
          ><span class="hidden lg:inline">Share</span>
          {destination.name} <span class="hidden sm:inline">link</span></span
        >
      </label>
    {/each}
  </form>

  <div class="p-6 space-y-8 border-t border-beige-200">
    <div class="space-y-4">
      <h3 class="text-2xl font-body">Share {destinations[selectedDestination].name} link</h3>
      <p class="mt-2">
        {destinations[selectedDestination].description}
      </p>
    </div>

    <div class="space-y-8">
      <div>
        <h3 class="space-x-2 flex items-center font-sans text-lg">
          <Icon name="links-line" />
          <span>Link</span>
        </h3>

        <div class="flex flex-col md:flex-row items-center justify-between mt-4 gap-2">
          <div class="flex-grow w-full md:w-auto">
            <label for="url" class="sr-only">Link:</label>
            <input
              id="url"
              type="text"
              value={destinations[selectedDestination].url}
              readonly
              class="px-5 py-2 w-full border border-neutral-200"
            />
          </div>

          <Button type="beige" class="w-full md:w-auto min-w-[150px]" on:click={downloadQr}>Download QR</Button>

          <CopyToClipboard let:copy value={destinations[selectedDestination].url} on:copy={handleCopy}>
            <Button type="accent" class="w-full md:w-auto min-w-[150px]" on:click={copy}>Copy Link</Button>
          </CopyToClipboard>
        </div>
      </div>

      <div>
        <h3 class="space-x-2 flex items-center font-sans text-lg">
          <Icon name="mail-line" />
          <span>Email</span>
        </h3>

        <form
          on:submit|preventDefault={handleSubmitEmail}
          class="flex flex-col md:flex-row items-center justify-between mt-4 gap-2"
        >
          <div class="flex-grow w-full md:w-auto">
            <label for="emails" class="sr-only">Enter email address(es) separated by commas:</label>
            <input
              id="emails"
              type="email"
              placeholder="Enter email address(es) separated by commas.."
              bind:value={$form.emails}
              class="px-5 py-2 w-full border border-neutral-200 bg-canvas text-neutral-600"
              multiple
              required
              name="emails"
            />
          </div>

          <SubmitButton type="accent" class="w-full md:w-auto ">Share with Email</SubmitButton>
        </form>
      </div>
    </div>
  </div>
</div>
