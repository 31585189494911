import { writable } from "svelte/store"
import axios from "axios"
import { route } from "ziggy-js"

import notifications from "./notifications"
import { collectionsStore } from "./collections"

function createSelectionManagementStore() {
  let currentProductId = null

  const store = writable({
    productSelectionCollectionIds: [],
    loadingProductSelections: false,
    product: null,
  })
  const { subscribe, update } = store

  return {
    subscribe,

    setProduct: (newProduct) => {
      currentProductId = newProduct.id

      update((oldState) => {
        return {
          ...oldState,
          product: newProduct,
          productSelectionCollectionIds: [],
        }
      })
    },

    setProductAndFetchSelectionCollectionIds: async (newProduct) => {
      currentProductId = newProduct.id

      update((oldState) => {
        return {
          ...oldState,
          loadingProductSelections: true,
          product: newProduct,
          productSelectionCollectionIds: [],
        }
      })

      try {
        const res = await axios.post(route("merchant.api.curation.product-check.store"), {
          product: newProduct.id,
        })

        update((oldState) => {
          return {
            ...oldState,
            productSelectionCollectionIds: res.data,
            loadingProductSelections: false,
          }
        })
      } catch (err) {
        currentProductId = null

        notifications.error({
          message: err,
        })
      }
    },

    addProductToCollection: async (collection) => {
      if (!currentProductId) {
        throw new Error("No product selected, can't add to a collection")
      }

      try {
        await axios.post(route("merchant.selections.store"), {
          collection_id: collection.id,
          product_id: currentProductId,
        })

        collectionsStore.incrementLocalProductsCount(collection)

        update((oldState) => {
          return {
            ...oldState,
            productSelectionCollectionIds: [...oldState.productSelectionCollectionIds, collection.id],
          }
        })
      } catch (err) {
        notifications.error({
          message: err,
        })
      }
    },

    removeProductFromCollection: async (collection) => {
      if (!currentProductId) {
        throw new Error("No product selected, can't remove from a collection")
      }

      try {
        await axios.delete(route("merchant.selections.destroy"), {
          data: {
            collection_id: collection.id,
            product_id: currentProductId,
          },
        })

        collectionsStore.decrementLocalProductsCount(collection)

        update((oldState) => {
          return {
            ...oldState,
            productSelectionCollectionIds: oldState.productSelectionCollectionIds.filter((collectionId) => {
              return collectionId !== collection.id
            }),
          }
        })
      } catch (err) {
        notifications.error({
          message: err,
        })
      }
    },
  }
}

export default createSelectionManagementStore()
