/**
 * Returns a string formatted as a currency.
 */
export function toHuman(date: string, opts: Record<string, unknown> = {}) {
  const defaultOpts = {
    locale: "en-US",
    options: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
  }

  opts = { ...defaultOpts, ...opts }

  const formatter = new Intl.DateTimeFormat(opts.locale as string, opts.options as Intl.DateTimeFormatOptions)

  return formatter.format(Date.parse(date))
}
