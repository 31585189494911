<script>
  import Icon from "@components/Icon.svelte"

  export let value
  export let min
  export let max = null
  export let disabled = false

  let input

  $: optionalProps = max !== null ? { max } : {}

  const increment = () => {
    input.stepUp()

    input.dispatchEvent(new Event("input"))
  }

  const decrement = () => {
    input.stepDown()

    input.dispatchEvent(new Event("input"))
  }
</script>

<div class="flex focus-within:ring-2">
  <button
    data-testid="QuantityInput__decrement-button"
    class="bg-neutral-50 py-1 px-3 text-sm"
    on:click|preventDefault={decrement}
    {disabled}
    class:opacity-50={disabled}
    aria-label="Decrement"
  >
    <Icon name="subtract" weight="fill" />
  </button>

  <input
    data-testid="QuantityInput__number-input"
    type="number"
    bind:this={input}
    bind:value
    on:input
    {min}
    {...optionalProps}
    {disabled}
    class:opacity-50={disabled}
    class="bg-canvas py-1 px-4 border border-neutral-50 w-full border-l-0 border-r-0 marker-none text-center text-sm font-semibold ring-transparent focus:border-neutral-200 focus:ring-0 focus:outline-none"
  />

  <button
    data-testid="QuantityInput__increment-button"
    class="bg-neutral-50 py-1 px-3 text-sm"
    on:click|preventDefault={increment}
    {disabled}
    class:opacity-50={disabled}
    aria-label="Increment"
  >
    <Icon name="add" weight="fill" />
  </button>
</div>
