import { writable } from "svelte/store"

export const PointerType = Object.freeze({
  Cursor: "Cursor",
  Touch: "Touch",
})

function createUserInputStore() {
  const { subscribe, update } = writable({
    pointerType: PointerType.Cursor,
  })

  const handleTouchstart = () => {
    document.removeEventListener("touchstart", handleTouchstart, true)
    update((oldVal) => {
      return {
        ...oldVal,
        pointerType: PointerType.Touch,
      }
    })
  }
  document.addEventListener("touchstart", handleTouchstart, true)

  return {
    subscribe,
  }
}

export default createUserInputStore()
