<script>
  export let size = "max"
  export let padding = "default"

  let className = ""
  export { className as class }

  const sizes = {
    tiny: "o-wrap--tiny [ max-w-[36rem] ]",
    small: "o-wrap--small [ max-w-[42rem] ]",
    medium: "o-wrap--base [ max-w-[56rem] ]",
    large: "o-wrap--large [ max-w-[64rem] ]",
    huge: "o-wrap--huge [ max-w-[84rem] ]",
    max: "o-wrap--max [ max-w-[120rem] ]",
  }

  const paddings = {
    default: "px-[8vw] md:px-[6vw] lg:px-[4vw] xl:px-[3vw]",
    none: "",
  }
</script>

<div
  data-testid="container"
  class="
    o-content-container
    o-wrap mx-auto
    {sizes[size]} {paddings[padding]} {className}
  "
>
  <slot />
</div>
