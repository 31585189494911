<script>
  import notifications from "@store/notifications"
  import { scale } from "svelte/transition"
  import { onMount } from "svelte"
  import Icon from "@components/Icon.svelte"

  export let notification
  export let timeout = 5000

  let timer

  const icon = {
    success: "checkbox-circle",
    error: "close-circle",
  }

  function close() {
    notifications.remove(notification)
  }

  function handleMouseEnter() {
    clearTimeout(timer)
  }

  function handleMouseLeave() {
    timer = setTimeout(() => close(), notification.duration || timeout)
  }

  onMount(() => {
    timer = setTimeout(() => close(), notification.duration || timeout)
  })
</script>

<div
  role="alert"
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
  transition:scale={{ duration: 300 }}
  class="c-notification [ bg-canvas text-stroke border p-4 rounded-md flex space-x-4 shadow-lg w-full ]"
>
  <div
    class="c-notification__figure [ shrink-0 flex justify-center ]"
    class:text-green-600={notification.type === "success"}
    class:text-red-600={notification.type === "error"}
    aria-hidden="true"
  >
    {#if notification.image}
      <img
        src={notification.image}
        class="[ block h-12 w-12 rounded ]"
        alt="Notification image for ${notification.id}"
        aria-hidden="true"
      />
    {:else}
      <Icon name={icon[notification.type]} weight="fill" class="[ text-2xl relative top-0.5 ]" />
    {/if}
  </div>

  <div class="c-notification__body [ w-full ]" class:self-center={!notification.title}>
    {#if notification.title}
      <div class="c-notification__title [ font-body font-bold tracking-tight ]">{notification.title}</div>
    {/if}
    <div class="c-notification__caption [ leading-tight mt-0.5 ]">{@html notification.message}</div>
  </div>
</div>
