<script context="module">
  import { createFloatingActions } from "svelte-floating-ui"
  import { offset, shift } from "svelte-floating-ui/dom"
  import { writable } from "svelte/store"
  import feature from "@store/feature"

  let isOpen = writable(false)
  let floatingContentEl
  let onClose = null

  const [floatingRef, floatingContent] = createFloatingActions({
    strategy: "absolute",
    placement: "bottom-end",
    middleware: [offset(10), shift({ padding: 10 })],
  })

  export const closeRecentCollectionsDropdown = () => {
    isOpen.update(() => false)
    if (onClose) {
      onClose()
    }
  }

  export const openRecentCollectionsDropdown = ({ triggerElement, onClose: newOnClose }) => {
    isOpen.update(() => true)
    if (newOnClose) {
      onClose = newOnClose
    }

    floatingRef(triggerElement)
    floatingContent(floatingContentEl)
  }
</script>

<script>
  import axios from "axios"
  import { route } from "ziggy-js"
  import * as focusTrap from "focus-trap"
  import { onMount } from "svelte"
  import Icon from "@components/Icon.svelte"
  import clickOutside from "@actions/use-click-outside"
  import { collectionsStore } from "@store/collections"
  import { openModal } from "svelte-modals"
  import notifications from "@store/notifications"
  import Button from "@components/Button.svelte"
  import widont from "@actions/use-widont"
  import Spinner from "@components/Spinner.svelte"
  import plural from "@helpers/plural"
  import Link from "@components/Link.svelte"
  import ShareCollectionDialog from "@components/dialogs/ShareCollectionDialog/ShareCollectionDialog.svelte"
  import { toHuman } from "@helpers/formatters/currency"

  $: restrictSharingFeature = feature.activated("firms-plus/disable-public-collections")

  let trap

  let isFloatingContentHidden = true
  let isLoadingSubtotals = false

  let recentCollections = []
  let collectionSubtotals = []

  const updateRecentCollections = async () => {
    recentCollections = $collectionsStore.collections
      .sort((coll1, coll2) => {
        return new Date(coll2.updated_at) - new Date(coll1.updated_at)
      })
      .slice(0, 3)

    isLoadingSubtotals = true
    try {
      const res = await axios.get(
        route("merchant.collections.subtotals", {
          collection_ids: recentCollections.map((c) => c.id),
        }),
      )

      collectionSubtotals = res.data
    } catch (err) {
      notifications.error({
        title: "Problem loading collection subtotals",
        message: "Please try again later.",
      })

      throw err
    } finally {
      isLoadingSubtotals = false
    }
  }

  $: {
    if ($isOpen) {
      isFloatingContentHidden = false
      trap.activate()

      if ($collectionsStore.initialLoadComplete) {
        updateRecentCollections()
      }
    } else {
      isLoadingSubtotals = false
    }
  }

  onMount(() => {
    collectionsStore.refresh()

    trap = focusTrap.createFocusTrap(floatingContentEl, {
      fallbackFocus: floatingContentEl,
      escapeDeactivates: false,
      clickOutsideDeactivates: false,
      allowOutsideClick: true,
    })
  })

  const closeComponent = () => {
    trap.deactivate()
    closeRecentCollectionsDropdown()
  }

  const handleWindowKeydown = (e) => {
    if (!$isOpen) {
      return
    }

    if (e.key === "Escape") {
      closeComponent()
    }
  }

  const handleClickOutside = (e) => {
    if ($isOpen) {
      e.detail.originalEvent.preventDefault()
      e.detail.originalEvent.stopPropagation()
      closeComponent()
    }
  }

  const openShareCollectionDialog = (collection) => {
    if (restrictSharingFeature) {
      return
    }

    closeComponent()
    openModal(ShareCollectionDialog, { collection })
  }
</script>

<svelte:window on:keydown={handleWindowKeydown} />

<!-- NOTE: This component doesn't contain its own `floatingRef` trigger to open the content. Instead, a reference to a DOM node is passed when this component is opened. This hidden DOM node is to avoid a console error. -->
<div aria-hidden="true" class="hidden" use:floatingRef />

{#if $isOpen}
  <div class="inset-0 fixed bg-linen z-[61] opacity-30 pointer-events-none" />
{/if}

<section
  data-testid="RecentCollectionsDropdown"
  use:floatingContent
  bind:this={floatingContentEl}
  class="z-[62] w-[400px] max-w-[95vw]"
  class:invisible={isFloatingContentHidden}
  on:outroend={() => (isFloatingContentHidden = true)}
  use:clickOutside
  on:click-outside={handleClickOutside}
>
  {#if $isOpen}
    <div data-testid="RecentCollectionsDropdown__dropdown" class="bg-white shadow rounded-sm">
      <header class="flex items-center justify-between py-4 px-4 sm:px-6 border-b border-neutral-50">
        <h4 class="text-xl">Recent collections</h4>

        <Button on:click={closeComponent} type="clear" size="none" class="w-9 h-9 flex items-center justify-center">
          <Icon name="close" weight="line" />
          <span class="sr-only">Close recent collections</span>
        </Button>
      </header>

      {#if $collectionsStore.initialLoadComplete}
        {#if recentCollections.length}
          <ol class="mb-2">
            {#each recentCollections as collection, i}
              <li
                class="flex items-center justify-between pt-3 pb-4 border-neutral-50 px-4 sm:px-6"
                class:border-t={i > 0}
              >
                <Link
                  href={route("merchant.collections.show", collection)}
                  on:click={closeComponent}
                  class="pr-1 sm:pr-4 group"
                >
                  <h5 class="flex items-center gap-1">
                    <span class="text-lg line-clamp-2">{collection.name}</span>

                    <Icon
                      name="arrow-right"
                      weight="line"
                      class="opacity-0 group-hover:opacity-50 transition -mb-0.5"
                    />
                  </h5>

                  <p class="text-xs flex items-center opacity-70">
                    <span class="shrink-0"
                      >{collection.products_count}
                      {plural("product", collection.products_count)}</span
                    >

                    {#if isLoadingSubtotals}
                      <span class="bg-cream-50 rounded-sm animate-pulse block w-[100px] h-3 ml-2">
                        <span class="sr-only">Loading…</span>
                      </span>
                    {:else}
                      {@const subtotal = collectionSubtotals.find((s) => {
                        return s.collection_id === collection.id
                      })}

                      {#if subtotal}
                        <span class="shrink-0">
                          {#if subtotal.wholesale}
                            , <span>{toHuman(subtotal.wholesale)}</span> wholesale subtotal
                          {:else if subtotal.merchant}
                            , <span class:blur={!subtotal.merchant}>{toHuman(subtotal.merchant)}</span> designer subtotal
                          {/if}
                        </span>
                      {/if}
                    {/if}
                  </p>
                </Link>

                <div class="flex gap-2 text-xl">
                  {#if !restrictSharingFeature}
                    <Button
                      on:click={() => openShareCollectionDialog(collection)}
                      type="clear"
                      size="none"
                      class="w-9 h-9 flex items-center justify-center"
                    >
                      <Icon name="share-forward" weight="line" />
                      <span class="sr-only">Share collection</span>
                    </Button>
                  {/if}

                  <Button
                    type="clear"
                    size="none"
                    class="w-9 h-9 flex items-center justify-center"
                    on:click={closeComponent}
                    href={route("merchant.checkout.cart.create", collection)}
                  >
                    <Icon name="shopping-cart" weight="line" />
                    <span class="sr-only">Begin designer checkout</span>
                  </Button>
                </div>
              </li>
            {/each}
          </ol>

          <footer class="flex flex-col items-center border-t p-4 sm:py-4 sm:px-6">
            <Button on:click={closeComponent} href={route("merchant.collections.index")} type="accent"
              >View all collections
              <Icon name="arrow-right" weight="line" class="ml-4" />
            </Button>
          </footer>
        {:else}
          <div class="flex flex-col items-center gap-2 px-4 py-2 mb-6">
            <img src="/images/illustrations/furniture-banner.png" alt="" class="px-10" />

            <p class="text-center px-4 max-w-[360px]" use:widont>
              You haven’t created any collections yet. Add products to collections to check out or share them with your
              clients and audience. You can <a
                href="https://support.onsidedoor.com/en/articles/2041345"
                class="underline"
                target="_blank">learn more about collections</a
              >
              in our help center!
            </p>
          </div>

          <footer class="flex flex-col items-center border-t p-4">
            <Button on:click={closeComponent} href={route("merchant.products.index")} type="accent"
              >Browse our catalog
              <Icon name="arrow-right" weight="line" class="ml-4" />
            </Button>
          </footer>
        {/if}
      {:else}
        <div class="flex p-8 items-center justify-center">
          <Spinner />
          <span class="sr-only">Loading…</span>
        </div>
      {/if}
    </div>
  {/if}
</section>
