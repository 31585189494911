<script>
  import { route } from "ziggy-js"
  import { getContext } from "svelte"

  import Link from "@components/Link.svelte"
  import { categoryItemEventHandlerContextKey } from "@components/AppHeader/TheCategoryItem.svelte"
  import feature from "@store/feature.js"

  export let categories
  export let depth = 1

  const { handleBlur, handleClick } = getContext(categoryItemEventHandlerContextKey)
</script>

<ul class="leading-[1.8]">
  {#each categories as category}
    <li>
      {#if category.routeParams}
        <Link
          href={route("merchant.products.index", category.routeParams)}
          on:click={handleClick}
          on:blur={handleBlur}
          class="block truncate"
        >
          <span class:font-bold={category.children}>
            {category.name}
          </span>
        </Link>
      {:else}
        <Link
          href={route("merchant.products.index", { categories: [category.path] })}
          on:click={handleClick}
          on:blur={handleBlur}
          class="block truncate"
        >
          <span class:font-bold={category.children}>
            {category.name}
          </span>
        </Link>
      {/if}

      {#if category.children}
        <div class="[ pl-4 mb-2 ]" class:border-l-2={depth === 1}>
          <svelte:self categories={category.children} depth={depth + 1} />
        </div>
      {/if}
    </li>
  {/each}
</ul>
