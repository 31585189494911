<script>
  import Icon from "@components/Icon.svelte"
  import selectionManagement from "@store/selectionManagement"
  import { collectionsStore } from "@store/collections"
  import userInput, { PointerType } from "@store/userInput"
  import { createEventDispatcher } from "svelte"
  import { openModal } from "svelte-modals"
  import CollectionDialog from "@components/dialogs/CollectionDialog/CollectionDialog.svelte"

  const dispatch = createEventDispatcher()

  export let collection

  let className
  export { className as class }

  let togglingSelection = false

  $: selectionExists = $selectionManagement.productSelectionCollectionIds.includes(collection.id)

  const toggleSelection = async () => {
    if (togglingSelection) {
      return
    }
    togglingSelection = true

    try {
      if (selectionExists) {
        await selectionManagement.removeProductFromCollection(collection)
      } else {
        await selectionManagement.addProductToCollection(collection)
      }

      collectionsStore.localTouch(collection)
    } finally {
      togglingSelection = false
    }
  }

  let hovering = false

  const handleMouseEnter = () => {
    hovering = true
  }

  const handleMouseLeave = () => {
    hovering = false
  }

  const openCollectionModal = () => {
    dispatch("close")

    openModal(CollectionDialog, {
      collection,
    })
  }
</script>

<li
  class="flex gap-2 hover:bg-neutral-50/50 {className}"
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
>
  <button
    on:click={toggleSelection}
    class="flex items-center grow gap-2 pl-2 py-2 outline-none ring-inset focus-visible:ring-1 no-tap-highlight group"
  >
    <span class="text-xl flex items-start group-active:scale-90 transition">
      {#if togglingSelection}
        <Icon name="loader-4" weight="line" class="inline-block animate-spin" />
      {:else if selectionExists}
        <Icon name="checkbox-circle" weight="fill" />
      {:else}
        <Icon name="checkbox-circle" weight="line" class="text-neutral-400" />
      {/if}
    </span>

    <span class="block line-clamp-2 text-left">{collection.name}</span>
  </button>

  <div class="flex items-center">
    <button
      class="relative w-[44px] h-[44px] text-neutral-500 outline-none ring-inset focus-visible:ring-1 group hover:scale-110 origin-right transition no-tap-highlight active:bg-neutral-100/50"
      on:click={openCollectionModal}
    >
      {#if $userInput.pointerType === PointerType.Cursor}
        <span
          class="not-sr-only absolute block right-3 top-1/2 mt-[-8px] bg-neutral-50/80 text-xs whitespace-nowrap rounded-sm pl-1 pr-5 transition duration-300 opacity-0 scale-0 group-hover:bg-white/80 origin-right shadow-sm"
          class:scale-100={hovering}
          class:opacity-100={hovering}>Quick look</span
        >
      {/if}

      <span class="sr-only">Quick look {collection.name}</span>
      <Icon name="arrow-right-up" weight="line" class="z-1 relative align-[-2px]" />
    </button>
  </div>
</li>
