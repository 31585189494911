<script>
  import { route } from "ziggy-js"
  import { getContext } from "svelte"

  import Link from "@components/Link.svelte"
  import CategoryList from "./CategoryList.svelte"
  import { categoryItemEventHandlerContextKey } from "@components/AppHeader/TheCategoryItem.svelte"

  export let category

  const { handleBlur, handleClick } = getContext(categoryItemEventHandlerContextKey)
</script>

<span class="grid-cols-[repeat(3,1fr)]" />

<div class="pt-2">
  <ul
    class="max-h-[60vh] lg:max-h-[calc(100vh-60px)] overflow-auto px-8 py-6 border shadow w-full lg:w-auto bg-canvas grid gap-12 contrast-more:border-neutral-600 {category.classes}"
  >
    {#each category.columns as column}
      {#each column as category}
        {@const routeParams = category.routeParams ? category.routeParams : { categories: [category.path] }}

        <li class="mb-4 min-w-0 lg:min-w-[auto]">
          <div>
            <div class="mb-1">
              <Link
                href={route("merchant.products.index", routeParams)}
                on:click={handleClick}
                on:blur={handleBlur}
                class="text-2xl font-heading truncate block"
              >
                {category.name}
              </Link>
            </div>

            {#if category.children}
              <CategoryList categories={category.children} />
            {/if}
          </div>
        </li>
      {/each}
    {/each}
  </ul>
</div>
