<script>
  import { route } from "ziggy-js"
  import { createEventDispatcher } from "svelte"
  import NoCollectionsIllustration from "./NoCollectionsIllustration.svelte"
  import Button from "@components/Button.svelte"
  import Icon from "@components/Icon.svelte"
  import widont from "@actions/use-widont"

  export let title = "Your collections will show up here."
  export let showCreateCollectionButton = false
  export let overrideBrowseAction = false

  const dispatch = createEventDispatcher()

  function handleBrowseCatalog() {
    dispatch("browse")
  }

  function handleCreateNewCollection() {
    dispatch("create")
  }
</script>

<section class="[ mx-auto ]">
  <div class="[ flex flex-row flex-wrap items-center justify-center ]">
    <div class="[ mx-8 w-48 text-neutral-500 ]">
      <NoCollectionsIllustration />
    </div>

    <div class="[ m-6 max-w-lg flex flex-col items-center lg:items-start ] [ text-center lg:text-left ]">
      <h2 class="[ text-4xl mb-2 ]" use:widont>{title}</h2>
      <p>
        Curate products into collections and share them with your clients and audience alike. <a
          class="[ text-accent-base ]"
          target="_blank"
          rel="noreferrer"
          href="https://support.onsidedoor.com/en/articles/2041345"
        >
          Click here
        </a> to learn more about Collections.
      </p>

      <div class="[ mt-8 flex flex-wrap gap-3 items-center justify-center lg:justify-start ]">
        {#if overrideBrowseAction}
          <Button type="neutral" on:click={handleBrowseCatalog}>Browse our catalog &rarr;</Button>
        {:else}
          <Button href={route("merchant.products.index")} type="neutral" on:click={handleBrowseCatalog}>
            Browse our catalog &rarr;
          </Button>
        {/if}

        {#if showCreateCollectionButton}
          <Button type="neutral" on:click={handleCreateNewCollection} class="[ font-semibold ]">
            <Icon name="add" weight="line" class="[ -ml-1 mr-3 ]" />
            <span>Create a new collection</span>
          </Button>
        {/if}
      </div>
    </div>
  </div>
</section>
