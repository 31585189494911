<script>
  import { route } from "ziggy-js"
  import { page } from "@inertiajs/svelte"
  import Container from "@objects/Container.svelte"
  import Combomark from "@components/logo/Combomark.svelte"
  import Link from "@components/Link.svelte"
  import Button from "@components/Button.svelte"
  import Icon from "@components/Icon.svelte"
  import IconGrid from "@components/svg/icons/IconGrid.svelte"
  import TheHeaderSearch from "@components/AppHeader/TheHeaderSearch.svelte"
  import TheUserAvatar from "@components/AppHeader/TheUserAvatar.svelte"
  import TheMobileNavigation from "@components/AppHeader/TheMobileNavigation.svelte"
  import auth from "@store/auth"
  import RecentCollectionsDropdown, {
    openRecentCollectionsDropdown,
  } from "@components/AppHeader/RecentCollectionsDropdown.svelte"

  export { className as class }
  let className = ""

  $: isFirmsPlus = $auth.merchant.is_firms_plus

  let clearanceHasActiveCampaign = $page.props.header.clearance.hasActiveCampaign
  let hasMobileNavigationOpen = false
  function toggleMobileNavigation() {
    hasMobileNavigationOpen = !hasMobileNavigationOpen
  }

  const showRecentCollectionsDropdown = (e) => {
    openRecentCollectionsDropdown({
      triggerElement: e.currentTarget,
    })
  }

  let alertHtml = $page.props.header.alertHtml
</script>

{#if alertHtml}
  <div role="alert" class="px-2 py-2 bg-amber-200 flex justify-center items-center">
    {@html alertHtml}
  </div>
{/if}

<header class="the-app-header py-9 border-b border-beige-200 bg-beige-50 {className}">
  <Container>
    <div class="flex items-center">
      <div class="flex items-center justify-between w-full md:w-auto">
        <div class="flex flex-col sm:flex-row items-end sm:items-center">
          <Link href={route("merchant.home")}>
            <Combomark class="h-8" />
            <span class="sr-only">SideDoor</span>
          </Link>

          {#if isFirmsPlus}
            <p class="text-right text-2xs sm:ml-2 sm:mb-[-9px] opacity-80 uppercase">Firms+</p>
          {/if}
        </div>

        <div class="md:hidden flex items-center gap-2">
          <Button
            type="beige"
            on:click={showRecentCollectionsDropdown}
            class="h-10 w-10 flex items-center justify-center text-eggplant-950"
          >
            <div>
              <IconGrid />
            </div>
            <span class="sr-only">Show recent collections</span>
          </Button>

          <Button type="beige" on:click={toggleMobileNavigation} class="h-10 w-10 flex items-center justify-center">
            <Icon name="menu" weight="fill" />
            <span class="sr-only">Toggle navigation</span>
          </Button>
        </div>
      </div>

      <div class="ml-auto items-center hidden md:flex gap-2 xl:gap-4">
        {#if clearanceHasActiveCampaign}
          <Link href={route("merchant.products.index", { "on-clearance": 1 })} class="inline-block ml-1">
            <span
              class="text-xs px-2.5 py-1 font-semibold text-white bg-red-500 rounded"
              class:opacity-50={!$auth.merchant.has_active_subscription}>Clearance</span
            >
          </Link>
        {/if}

        <TheHeaderSearch class="the-header__search shrink-0" id="the-desktop-search" />

        <TheUserAvatar />

        <Button
          on:click={showRecentCollectionsDropdown}
          testid="ShowRecentCollectionsDropdown__trigger"
          type="beige"
          size="none"
          class="w-9 h-9 flex items-center justify-center text-eggplant-950"
        >
          <IconGrid />
          <span class="sr-only">Show recent collections</span>
        </Button>
      </div>
    </div>
  </Container>

  <TheMobileNavigation class="md:hidden" bind:open={hasMobileNavigationOpen} on:close={toggleMobileNavigation} />

  <RecentCollectionsDropdown />
</header>
