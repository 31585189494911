<script>
  import { route } from "ziggy-js"
  import { createEventDispatcher } from "svelte"
  import auth from "@store/auth"
  import Link from "@components/Link.svelte"
  import { signOut } from "@helpers/models/user"
  import feature from "@store/feature"

  const classes = `block px-4 py-1.5 rounded w-full text-left bg-canvas hover:bg-neutral-100 focus:bg-neutral-100`
  const dispatch = createEventDispatcher()

  function close() {
    dispatch("close")
  }
</script>

<nav class="text-lg">
  <div class="px-4 pt-2 pb-1 leading-tight">
    <p class="text-neutral-700">
      {#if $auth.is_impersonating}
        Impersonating
      {:else}
        Signed in as
      {/if}
    </p>

    <p class="tracking-tight truncate">
      <strong>{$auth.designer.email}</strong>
    </p>
  </div>

  <Link on:click={close} href={route("merchant.multi-sign-in.show")} class={classes}>Switch account…</Link>

  <hr class="my-2" />

  <div class="py-1">
    <Link on:click={close} href={route("merchant.orders.index")} class={classes}>View orders</Link>

    {#if feature.activated("merchant/invoicing")}
      <Link on:click={close} href={route("merchant.invoices.index")} class={classes}>Invoices</Link>
    {/if}

    {#if $auth.merchant.can_be.managed}
      <Link on:click={close} href={route("merchant.account.overview")} class={classes}>Account settings</Link>
    {/if}

    <Link on:click={close} href={route("merchant.user-settings")} class={classes}>User settings</Link>

    {#if $auth.designer.has_superpowers}
      <a href="/admin/home" class={classes}>Admin home</a>
    {/if}
  </div>

  <hr class="my-2" />

  <div>
    {#if $auth.is_impersonating}
      <a href={route("impersonate.leave")} class={classes}>Leave impersonation</a>
    {:else}
      <form on:submit|preventDefault={signOut}>
        <button type="submit" class={classes}>Sign out</button>
      </form>
    {/if}
  </div>
</nav>
