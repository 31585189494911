<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import Dialog from "@objects/Dialog.svelte"
  import DialogBody from "@objects/DialogBody.svelte"
  import Card from "@objects/Card.svelte"
  import { useForm } from "@inertiajs/svelte"
  import FormField from "@components/FormField.svelte"
  import Input from "@components/Input.svelte"
  import Textarea from "@components/Textarea.svelte"
  import Details from "@objects/Details.svelte"
  import Icon from "@components/Icon.svelte"
  import Button from "@components/Button.svelte"
  import SubmitButton from "@components/SubmitButton.svelte"
  import Analytics from "@services/Analytics"
  import notifications from "@store/notifications"
  import SelectionImageForm from "@components/SelectionImageForm.svelte"
  import Dropdown from "@components/Dropdown.svelte"
  import DropdownMenu from "@components/DropdownMenu.svelte"
  import { closeAllModals, closeModal, openModal } from "svelte-modals"
  import UpdateSelectionAndApplyToAllConfirmDialog from "@pages/collections/UpdateSelectionAndApplyToAllConfirmDialog.svelte"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"

  export let collection
  export let selection
  export let onClose
  export let onUpdate

  const form = useForm({
    name: selection.name || "",
    description: selection.description || "",
    show_default_images: selection.show_default_images,
    apply_to_all: false,
  })

  const close = () => {
    onClose && onClose()
    closeModal()
  }

  function handleImageFormUpdate(event) {
    selection = event.detail.selection
    onUpdate && onUpdate(event.detail.selection)
  }

  function handleKeydown(event) {
    if (event.key === "Escape") {
      close()
    }
  }

  function resetField(field) {
    $form[field] = selection.product[field]
  }

  async function submit() {
    try {
      const response = await axios.put(
        route("merchant.collections.selections.update", [collection, selection]),
        $form.data(),
      )

      Analytics.track("Collection product details updated", {
        collectionId: selection.collection_id,
        productId: selection.product_id,
        name: $form.name,
        description: $form.description,
      })

      notifications.success({ message: "Selection updated successfully." })
      onUpdate && onUpdate(response.data)
      closeModal()
    } catch (error) {
      $form.setError(error.response.data.errors)
      notifications.error({ message: "There was an error updating the selection." })
    }
  }

  async function updateAndApplyToAll() {
    openModal(UpdateSelectionAndApplyToAllConfirmDialog, {
      onConfirm: async () => {
        $form.apply_to_all = true
        await submit()
        $form.reset("apply_to_all")
      },
    })
  }
</script>

<svelte:window on:keydown={handleKeydown} />

<Dialog on:close={closeAllModals}>
  <DialogBody class="[ shadow-xl md:max-w-2xl w-full ]">
    <Card type="canvas" size="modal">
      <DialogHeader title="Update {selection.product.name}" />
      <p class="[ mt-2 ]">
        You can modify the product details that your customers will see while viewing this collection.
      </p>

      <SelectionImageForm {selection} images={selection.images} on:updated={handleImageFormUpdate} />

      <form on:submit|preventDefault={submit} class="[ grid gap-2 mt-6 ]">
        <div>
          {#if selection.images.length > 0}
            <div class="[ flex items-center space-x-2 ]">
              <input
                id="show-default-image"
                type="checkbox"
                name="show_default_images"
                bind:checked={$form.show_default_images}
              />
              <label for="show-default-image">Show original product images</label>
            </div>
          {/if}

          <FormField label="Name" name="name" class="[ relative mt-4 ]" error={$form.errors.name}>
            <Input id="name" name="name" bind:value={$form.name} />

            <button type="button" on:click={() => resetField("name")} class="[ absolute top-0 right-0 ]">
              {#if $form.name !== selection.product.name}
                <span class="[ text-sm font-normal text-accent-base hover:underline ]"> Reset name </span>
              {/if}
            </button>
          </FormField>

          {#if $form.name !== selection.product.name}
            <span class="[ text-sm leading-tight ]">Original name: {selection.product.name}</span>
          {/if}
        </div>

        <div>
          <FormField label="Description" name="description" class="[ relative mt-4 ]" error={$form.errors.description}>
            <Textarea
              id="description"
              name="description"
              bind:value={$form.description}
              class="[ resize-none overflow-y-scroll h-[6.25rem] ]"
            />
            {#if $form.description !== selection.product.description}
              <button type="button" on:click={() => resetField("description")} class="[ absolute top-0 right-0 ]">
                <span class="[ text-sm font-normal text-accent-base hover:underline ]"> Reset description </span>
              </button>
            {/if}
          </FormField>

          {#if $form.description !== selection.product.description}
            <Details>
              <button
                slot="summary"
                type="button"
                let:toggle
                let:open
                on:click={toggle}
                class="[ group mt-2 flex justify-between w-full ]"
              >
                <span class="[ text-sm leading-tight group-hover:underline ]">
                  {#if open}
                    Hide original description
                  {:else}
                    See original description
                  {/if}
                </span>
                <span aria-hidden="true" class:rotate-90={open} class="[ transition-transform ]">
                  <Icon name="arrow-right-s" weight="line" class="[ leading-tight ]" />
                </span>
              </button>
              <div class="[ max-h-28 overflow-y-scroll ] [ px-5 py-2 border-l-2 ]">
                <span class="[ text-sm ]">{selection.product.description ?? ""}</span>
              </div>
            </Details>
          {/if}
        </div>

        <div class="[ mt-6 flex flex-col md:flex-row md:justify-end ]">
          <Button type="outline" on:click={close} class="order-2 md:order-1 mt-4 md:mt-0">Cancel</Button>

          <div class="order-1 md:order-2 md:ml-4 flex items-center">
            <SubmitButton processing={$form.processing} class="w-full rounded-r-none">Update Product</SubmitButton>

            <Dropdown>
              <svelte:fragment slot="trigger" let:toggle>
                <Button type="primary" on:click={toggle} class="px-3 rounded-l-none border-l border-l-white/30">
                  <Icon name="arrow-down-s" weight="line" class="text-white/60" />
                  <span class="sr-only">More Options</span>
                </Button>
              </svelte:fragment>

              <DropdownMenu slot="menu" class="w-[250px]">
                <Button
                  size="none"
                  type="canvas"
                  disabled={$form.processing}
                  on:click={updateAndApplyToAll}
                  class="w-full !justify-start"
                >
                  Update and Apply to All
                </Button>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </form>
    </Card>
  </DialogBody>
</Dialog>
