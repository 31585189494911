export const getUnitPriceSuffix = (product) => {
  switch (product?.quantity_type) {
    case "yards":
      return "/yd"
    case "rolls":
      return "/roll"
    case "square_feet":
      return "/sq ft"
    case "hides":
      return "/hide"
    default:
      return ""
  }
}

export const getSingularUnitName = (product) => {
  switch (product?.quantity_type) {
    case "yards":
      return "yard"
    case "rolls":
      return "roll"
    case "square_feet":
      return "sq ft"
    case "hides":
      return "hide"
    default:
      return "unit"
  }
}

export const hasValidSourceUrl = (product) => {
  try {
    new URL(product?.source_url)

    return true
  } catch {
    return false
  }
}
