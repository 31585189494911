<script>
  import clickOutside from "@actions/use-click-outside"
  import { setContext } from "./context"
  import { useCombobox } from "./store"
  import { useHandles } from "./handles"
  import { useCursor } from "./cursor"
  export let as = "div"
  export let value = null
  export let multiple = false
  const combobox = useCombobox({ value, multiple })
  const handles = useHandles()
  const cursor = useCursor(combobox)
  $: $combobox.value = value
  combobox.subscribe((state) => {
    if (value === state.value) {
      return
    }
    value = state.value
  })
  setContext({
    combobox,
    cursor,
    handles,
  })
</script>

<svelte:element
  this={as}
  bind:this={$combobox.$root}
  {...$$restProps}
  use:clickOutside
  on:click-outside={combobox.close}
  on:open
  on:close
  on:input
  on:click-outside
  id={handles.firstOrNew("root")}
>
  <slot {combobox} state={$combobox} />
</svelte:element>
