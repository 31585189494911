<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import { openModal } from "svelte-modals"
  import { createEventDispatcher } from "svelte"
  import link from "@actions/use-link"
  import { useForm } from "@inertiajs/svelte"
  import { merchant } from "@store/auth"
  import { toHuman } from "@helpers/formatters/currency"
  import ProductDialog from "@components/dialogs/ProductDialog/ProductDialog.svelte"
  import Image from "@components/Image.svelte"
  import Pill from "@components/Pill.svelte"
  import Button from "@components/Button.svelte"
  import QuantityInput from "@pages/collections/QuantityInput.svelte"
  import notifications from "@store/notifications"
  import debounce from "lodash.debounce"
  import Analytics from "@services/Analytics"
  import Icon from "@components/Icon.svelte"
  import ProductAvailability from "@components/ProductAvailability.svelte"
  import UpdateSelectionDialog from "@components/dialogs/UpdateSelectionDialog/UpdateSelectionDialog.svelte"
  import IconDownloadImage from "@components/svg/icons/IconDownloadImage.svelte"
  import plural from "@helpers/plural"
  import Dropdown from "@components/Dropdown.svelte"
  import { getUnitPriceSuffix } from "@helpers/models/product"

  export let collection
  export let collectionSettings
  export let selection
  export let product
  export let enableEditing = true
  export let enableDragHandle = false

  const dispatch = createEventDispatcher()

  const ClearanceIndicator = {
    badge: "bg-red-500",
    text: "text-red-500",
  }

  let updatingIsSample = false

  $: enableSamples = product.is_sampleable
  $: unitPriceSuffix = getUnitPriceSuffix(product)

  $: merchantPriceTitle = product.merchant_price
    ? undefined
    : `Add your resale certificate to view ${$merchant.type.toLowerCase()} pricing!`

  $: isSampleRequest = selection.is_sample_request && product.is_sampleable

  const url = route("merchant.products.show", [product, product.slug])

  let form = useForm({
    quantity: selection.quantity,
  })

  const sampleForm = useForm({
    is_sample_request: selection.is_sample_request,
  })

  const removeForm = useForm()

  const open = (event) => {
    if (!product.is_purchasable) {
      return
    }

    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      return
    }

    event.preventDefault()

    openModal(ProductDialog, {
      productUrl: route("merchant.products.show", [product.id, product.slug]),
    })
  }

  const updateSelection = debounce(async () => {
    try {
      let response = await axios.put(
        route("merchant.collections.selections.update", [collection, selection]),
        $form.data(),
      )

      Analytics.track("Collection product updated", {
        collectionId: selection.collection_id,
        productId: selection.product_id,
        quantity: $form.data().quantity,
      })

      dispatch("updated", { selection: response.data })
    } catch (error) {
      notifications.error({ message: "Unable to update quantity." })
      $form.quantity = selection.quantity
    }
  }, 200)

  const updateIsSampleRequest = async () => {
    updatingIsSample = true

    try {
      const res = await axios.put(
        route("merchant.collections.selections.update", [collection, selection]),
        $sampleForm.data(),
      )

      Analytics.track("Collection product updated", {
        collectionId: selection.collection_id,
        productId: selection.product_id,
        isSampleRequest: $sampleForm.is_sample_request,
      })

      dispatch("updated", { selection: res.data })
    } catch (err) {
      notifications.error({ message: "Unable to change sample selection" })
      $sampleForm.is_sample_request = selection.is_sample_request
    } finally {
      updatingIsSample = false
    }
  }

  async function removeFromCollection() {
    if (!confirm("Are you sure you want to remove this product from this collection?")) {
      return
    }

    try {
      await axios.delete(route("merchant.collections.selections.destroy", [collection, selection]))

      Analytics.track("Collection product removed", {
        collectionId: selection.collection_id,
        productId: selection.product_id,
      })

      notifications.success({ message: "Product successfully removed from collection" })
      dispatch("deleted", { selection })
    } catch (error) {
      notifications.error({ message: "Unable to remove product from collection" })
      $form.setError(error.response.data.errors)
    }
  }

  function edit() {
    Analytics.track("Collection product view details form", {
      collectionId: selection.collection_id,
      productId: selection.product_id,
    })

    openModal(UpdateSelectionDialog, {
      collection,
      selection,
      onUpdate: (updatedSelection) => {
        dispatch("updated", { selection: updatedSelection })
      },
    })
  }

  let isDownloadingFeaturedImage = false

  const downloadFeaturedImage = async () => {
    isDownloadingFeaturedImage = true

    try {
      // Create local blob; `download` attr ignored for cross-origin
      const image = await fetch(selection.featured_image.url)
      const imageBlob = await image.blob()
      const imageUrl = URL.createObjectURL(imageBlob)

      const link = document.createElement("a")
      link.href = imageUrl
      link.download = product.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } finally {
      isDownloadingFeaturedImage = false
    }
  }
</script>

<article
  class="c-selection-card relative group flex flex-col bg-white/50"
  class:c-selection-card--unavailable={!product.is_purchasable}
  data-testid="SelectionCard"
>
  {#if !product.is_purchasable}
    <Pill size="small" type="destructive" class="mt-4 ml-4 shadow absolute top-0 left-0 z-[1]">Discontinued</Pill>
  {/if}

  <a
    on:click={open}
    href={url}
    use:link
    class="peer group outline-none focus:ring-2 ring-eggplant-700 ring-offset-2 block"
    tabindex="-1"
    class:pointer-events-none={!product.is_purchasable}
    class:cursor-not-allowed={!product.is_purchasable}
  >
    <div class="relative border overflow-hidden">
      {#if product.has_clearance_wholesale_price || product.has_clearance_merchant_price || product.has_clearance_retail_price}
        <div
          data-testid="SelectionCard__clearance-badge"
          class="flex items-center text-2xs lg:text-xs rounded shadow z-content absolute bottom-4 left-4 pointer-events-none
        "
        >
          <span
            class="px-2 py-1 rounded rounded-r-none border border-red-500 font-semibold text-white {ClearanceIndicator.badge}"
          >
            On Clearance
          </span>

          <span class="px-2 py-1 rounded bg-white rounded-l-none border border-red-500">
            For {product.clearance_time_remaining}
          </span>
        </div>
      {/if}

      <div
        class:opacity-50={!product.is_purchasable}
        class:group-hover:opacity-0={selection.hover_image && product.is_purchasable}
        class:group-focus:opacity-0={selection.hover_image && product.is_purchasable}
      >
        <Image
          src={selection.featured_image.url}
          width="350"
          alt="Featured image for {product.name}"
          fit="contain"
          class="c-selection-card__image c-selection-card__image--featured aspect-square"
        />
      </div>

      {#if selection.hover_image && product.is_purchasable}
        <div class="absolute h-full w-full inset-0 opacity-0 group-hover:opacity-100 group-focus:opacity-100">
          <Image
            src={selection.hover_image.url}
            width="350"
            alt="Secondary image for {product.name}"
            fit="contain"
            class="c-selection-card__image c-selection-card__image--hover aspect-square"
          />
        </div>
      {/if}
    </div>
  </a>

  <div class="c-selection-card__body mt-4 flex flex-col flex-grow">
    {#if collectionSettings.shows_vendor_name}
      <p
        class="c-selection-card__vendor text-sm text-neutral-700 font-medium"
        class:opacity-50={!product.is_purchasable}
      >
        {product.vendor.name}
      </p>
    {/if}

    <div class="flex gap-1 justify-between">
      <a
        on:click={open}
        href={url}
        use:link
        class:pointer-events-none={!product.is_purchasable}
        class:cursor-not-allowed={!product.is_purchasable}
        class:opacity-50={!product.is_purchasable}
      >
        <div class="c-selection-card__name font-heading text-2xl line-clamp-2 tracking-tight">
          {selection.name || product.name}
        </div>
      </a>

      {#if enableDragHandle}
        <div
          role="button"
          tabindex="0"
          on:touchstart|preventDefault
          on:pointerdown={(e) => dispatch("dragHandlePointerdown", e)}
          on:pointerup={(e) => dispatch("dragHandlePointerup", e)}
          on:keydown={(e) => dispatch("dragHandleKeydown", e)}
          class="p-2 opacity-80 cursor-grab"
        >
          <Icon name="drag-move-2" weight="line" />
          <span class="sr-only">Adjust sort order</span>
        </div>
      {/if}
    </div>

    <div class="mt-auto pt-6" class:opacity-50={!product.is_purchasable}>
      <div class="c-selection-card__pricing">
        <div class="flex gap-8">
          {#if isSampleRequest}
            <div data-testid="SelectionCard__designer-sample-price" class="leading-tight space-y-1">
              <div class="uppercase tracking-wide text-xs font-semibold">Sample</div>
              <div
                class="c-product__price c-product__price--wholesale text-xl font-bold tracking-[-0.04em] flex flex-col"
              >
                <span>{toHuman(product.sample_price_in_cents / 100)}/ea</span>

                <span class="text-sm" aria-hidden="true">&nbsp;</span>
              </div>
            </div>
          {:else}
            {#if product.wholesale_price}
              <div data-testid="SelectionCard__wholesale-price" class="leading-tight space-y-1">
                <div class="uppercase tracking-wide text-xs font-semibold">Wholesale</div>
                <div
                  class="c-product__price c-product__price--wholesale text-xl font-bold tracking-[-0.04em] flex flex-col"
                >
                  <span>
                    {toHuman(product.wholesale_price)}<wbr />{unitPriceSuffix}
                  </span>

                  {#if product.has_clearance_wholesale_price}
                    <span class="sr-only">
                      On sale from {toHuman(product.original_wholesale_price)}
                    </span>

                    <span class="line-through text-sm {ClearanceIndicator.text}" aria-hidden="true">
                      {toHuman(product.original_wholesale_price)}
                    </span>
                  {:else}
                    <span class="text-sm" aria-hidden="true">&nbsp;</span>
                  {/if}
                </div>
              </div>
            {/if}

            {#if !product.wholesale_price}
              <div
                data-testid="SelectionCard__merchant-price"
                class="leading-tight space-y-1"
                aria-hidden={product.merchant_price ? undefined : "true"}
              >
                <div class="uppercase tracking-wide text-xs font-semibold">{$merchant.type}</div>
                <div
                  class="c-product__price c-product__price--merchant text-xl font-semibold tracking-[-0.04em] flex flex-col"
                  class:blur={!product.merchant_price}
                  class:select-none={!product.merchant_price}
                  title={merchantPriceTitle}
                >
                  <span>
                    {toHuman(product.merchant_price)}<wbr />{unitPriceSuffix}
                  </span>

                  {#if product.has_clearance_merchant_price}
                    <span class="[ sr-only ]">
                      On sale from {toHuman(product.original_merchant_price)}
                    </span>

                    <span class="line-through text-sm {ClearanceIndicator.text}" aria-hidden="true">
                      {toHuman(product.original_merchant_price)}
                    </span>
                  {:else}
                    <span class="[ text-sm ]" aria-hidden="true">&nbsp;</span>
                  {/if}
                </div>
              </div>
            {/if}

            <div class="leading-tight space-y-1">
              <div class="uppercase tracking-wide text-xs font-semibold">Retail</div>
              <div
                class="c-product__price c-product__price--retail text-xl font-semibold tracking-[-0.04em] flex flex-col"
              >
                <span>
                  {toHuman(product.retail_price)}<wbr />{unitPriceSuffix}
                </span>
                {#if product.has_clearance_retail_price}
                  <span class="line-through text-gray-400">{toHuman(product.original_retail_price)}</span>
                {/if}
              </div>
            </div>
          {/if}
        </div>

        <div class="mt-1 flex flex-wrap items-center gap-2">
          {#if product.profit && !isSampleRequest}
            <Pill type="profit" size="tiny">
              <strong class="bold">{toHuman(product.profit)}</strong> profit
            </Pill>
          {/if}

          <ProductAvailability {product} sample={isSampleRequest} />
        </div>
      </div>

      <div class="mt-auto pt-4">
        <QuantityInput
          bind:value={$form.quantity}
          on:input={updateSelection}
          min="1"
          disabled={$form.processing || !enableEditing}
        />

        {#if $form.errors.quantity}
          <p class="mt-1 text-red-600 text-sm">{$form.errors.quantity}</p>
        {/if}
      </div>

      <div class="mt-2 flex gap-2 h-7">
        {#if enableSamples}
          <label class="flex items-center gap-2" class:opacity-50={updatingIsSample}>
            <input
              type="checkbox"
              bind:checked={$sampleForm.is_sample_request}
              on:change={updateIsSampleRequest}
              disabled={updatingIsSample}
            />
            Order {plural("sample", $form.quantity)}
          </label>

          <Dropdown placement="top-middle">
            <svelte:fragment slot="trigger" let:close let:openMenu>
              <div on:mouseenter={openMenu} on:mouseleave={close} class="cursor-help text-xl">
                <span class="sr-only">About samples</span>
                <Icon name="information" weight="line" />
              </div>
            </svelte:fragment>

            <svelte:fragment slot="menu">
              <div class="w-[220px] bg-white text-xs p-2 shadow">
                When selected, checking out will place a sample order. If you share this collection with customers,
                they'll be able to purchase samples themselves.
              </div>
            </svelte:fragment>
          </Dropdown>
        {/if}
      </div>
    </div>

    <div
      class="aspect-square absolute w-full top-0 left-0 transition-opacity opacity-0 group-hover:opacity-100 focus-within:opacity-100"
    >
      <div class="flex items-center justify-center absolute inset-0">
        <a
          on:click={open}
          use:link
          href={route("merchant.products.show", [product.id, product.slug])}
          tabindex="-1"
          class="c-line-item-card__overlay bg-black bg-opacity-50 absolute inset-0 h-full w-full"
          class:hidden={!product.is_purchasable}
        >
          <span class="sr-only">View {product.name}</span>
        </a>

        <div class="absolute top-4 right-0 mr-4 z-content flex gap-1">
          {#if enableEditing}
            <Button
              size="small"
              type="neutral"
              class="px-2"
              disabled={isDownloadingFeaturedImage}
              on:click={downloadFeaturedImage}
            >
              <IconDownloadImage />
              <span class="sr-only">Download featured image</span>
            </Button>
          {/if}

          {#if product.is_purchasable && enableEditing}
            <Button size="small" type="neutral" on:click={edit} disabled={$form.processing} class="px-2">
              <Icon name="pencil" weight="line" />
              <span class="sr-only">Edit product details</span>
            </Button>
          {/if}

          {#if enableEditing}
            <Button
              size="small"
              type="destructive"
              on:click={removeFromCollection}
              disabled={$removeForm.processing}
              class="px-2"
            >
              <Icon name="delete-bin" weight="line" />
              <span class="sr-only">Remove from collection</span>
            </Button>
          {/if}
        </div>

        <div class="relative z-10 text-sm flex flex-col space-y-4">
          {#if product.is_purchasable}
            <Button size="small" type="neutral" on:click={open}>Quick Look</Button>
          {/if}
        </div>
      </div>
    </div>
  </div>
</article>
