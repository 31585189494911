<script>
  import { route } from "ziggy-js"
  import Dialog from "@objects/Dialog.svelte"
  import DialogBody from "@objects/DialogBody.svelte"
  import Card from "@objects/Card.svelte"
  import axios from "axios"
  import notifications from "@store/notifications"
  import { closeAllModals, closeModals, openModal } from "svelte-modals"
  import Button from "@components/Button.svelte"
  import FormField from "@components/FormField.svelte"
  import CreateCollectionDialog from "@components/dialogs/CreateCollectionDialog/CreateCollectionDialog.svelte"
  import DialogActions from "@components/dialogs/CouponDialog/DialogActions.svelte"
  import CollectionListbox from "@components/Collection/CollectionListbox.svelte"
  import { onMount } from "svelte"
  import { collectionsStore } from "@store/collections"
  import Analytics from "@services/Analytics"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"

  export let isOpen
  export let onClose
  export let coupon
  export let collection
  export let products
  export let attemptedPercentOff

  let processing
  let destinationCollection

  async function moveProducts() {
    try {
      processing = true
      await axios.post(route("merchant.collections.selections.relocations.store", { id: destinationCollection.id }), {
        product_ids: products.map((product) => product.id),
        detach_from_collection_id: collection.id,
      })

      notifications.success({ message: "Products moved to a new collection." })

      Analytics.track("Merchant moved invalid products to new collection to facilitate a coupon discount amount", {
        // A user can arrive at the MoveProductsDialog in both cases where they're creating
        // a new coupon or trying to update the amount of an existing coupon.
        couponId: coupon?.id,
        percentOff: attemptedPercentOff,
        collectionId: collection.id,
        destinationCollectionId: destinationCollection.id,
      })

      if (onClose && typeof onClose === "function") {
        onClose()
      }

      closeModals(2)
    } catch (e) {
      console.error(e)
      notifications.error({ message: "Products could not be moved to the selection collection." })
    } finally {
      processing = false
    }
  }

  async function openCreateNewCollectionDialog() {
    openModal(CreateCollectionDialog, {
      onCreate: async (collection) => {
        notifications.success({ message: `${collection.name} was created successfully.` })

        await collectionsStore.refresh()
        destinationCollection = collection
      },
    })
  }

  function handleCollectionInput(event) {
    if (!event.detail) {
      return
    }

    destinationCollection = event.detail.value
  }

  onMount(async () => {
    await collectionsStore.refresh()
  })
</script>

{#if isOpen}
  <Dialog on:close={closeAllModals}>
    <DialogBody class="shadow-xl md:max-w-2xl w-full">
      <Card size="modal" type="canvas" class="space-y-8 relative">
        <DialogHeader title="Move ineligible products" />

        <div class="space-y-2">
          <FormField label="Destination Collection" name="destinationCollection" class="relative">
            <CollectionListbox
              bind:collection={destinationCollection}
              class="w-full"
              on:input={handleCollectionInput}
            />
          </FormField>

          <p class="text-center">or</p>

          <Button class="w-full" type="neutral" on:click={openCreateNewCollectionDialog} disabled={processing}>
            Create a new collection
          </Button>
        </div>

        <DialogActions>
          <Button type="primary" on:click={moveProducts} disabled={processing || !destinationCollection}>
            Move Products
          </Button>
        </DialogActions>
      </Card>
    </DialogBody>
  </Dialog>
{/if}
