import { MenuItem } from "@layouts/categories/index"

export const Bedding: MenuItem = {
  name: "Bedding",
  path: "bed-bath.bedding",
  children: [
    {
      name: "Duvet Covers",
      path: "bed-bath.bedding.duvet-covers",
      children: [
        {
          name: "Shams & Bedskirts",
          path: "bed-bath.bedding.duvet-covers.shams-bedskirts",
        },
      ],
    },
    {
      name: "Sheets & Pillow Cases",
      path: "bed-bath.bedding.sheets-pillow-cases",
    },
    {
      name: "Blankets & Quilts",
      path: "bed-bath.bedding.blankets-quilts",
    },
    {
      name: "Duvet Covers, Shams & Bedskirts",
      path: "bed-bath.bedding.duvet-covers-shams-bedskirts",
    },
  ],
}

export const Bath: MenuItem = {
  name: "Bath",
  path: "bed-bath.bath",
  children: [
    {
      name: "Bath Accessories",
      path: "bed-bath.bath.bath-accessories",
    },
  ],
}
