import { MenuItem } from "@layouts/categories/index"

export const OutdoorFurniture: MenuItem = {
  name: "Outdoor Furniture",
  path: "outdoor.outdoor-furniture",
  children: [
    {
      name: "Accent Chairs",
      path: "outdoor.outdoor-furniture.accent-chairs",
    },
    {
      name: "Umbrellas & Stands",
      path: "outdoor.outdoor-furniture.umbrellas-stands",
    },
    {
      name: "Storage & Covers",
      path: "outdoor.outdoor-furniture.storage-covers",
    },
    {
      name: "Dining Furniture",
      path: "outdoor.outdoor-furniture.dining-furniture",
    },
    {
      name: "Occasional Chairs",
      path: "outdoor.outdoor-furniture.occasional-chairs",
    },
    {
      name: "Lounge Furniture",
      path: "outdoor.outdoor-furniture.lounge-furniture",
    },
    {
      name: "Accent Tables",
      path: "outdoor.outdoor-furniture.accent-tables",
    },
  ],
}

export const FirePitsAndTables: MenuItem = {
  name: "Fire Pits & Tables",
  path: "outdoor.fire-pits-tables",
}

export const OutdoorLighting: MenuItem = {
  name: "Outdoor Lighting",
  path: "outdoor.outdoor-lighting",
  children: [
    {
      name: "Other Outdoor Lighting",
      path: "outdoor.outdoor-lighting.other-outdoor-lighting",
    },
  ],
}

export const OutdoorAccents: MenuItem = {
  name: "Outdoor Accents",
  path: "outdoor.outdoor-accents",
  children: [
    {
      name: "Garden Stools",
      path: "outdoor.outdoor-accents.garden-stools",
    },
    {
      name: "Lanterns",
      path: "outdoor.outdoor-accents.lanterns",
    },
    {
      name: "Planters & Garden",
      path: "outdoor.outdoor-accents.planters-garden",
    },
  ],
}

export const OutdoorRugsAndDoormats: MenuItem = {
  name: "Outdoor Rugs & Doormats",
  path: "outdoor.outdoor-rugs-doormats",
  children: [
    {
      name: "Doormats",
      path: "outdoor.outdoor-rugs-doormats.doormats",
    },
  ],
}
