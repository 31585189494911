import { route } from "ziggy-js"
import axios from "axios"
import notifications from "@store/notifications"

const MULTI_SIGN_IN_TOKEN_LOCALSTORAGE_KEY = "sd/designer/multi-sign-in-token"

export const getSavedMultiSignInToken = () => {
  return localStorage.getItem(MULTI_SIGN_IN_TOKEN_LOCALSTORAGE_KEY)
}

export const saveMultiSignInToken = (token) => {
  return localStorage.setItem(MULTI_SIGN_IN_TOKEN_LOCALSTORAGE_KEY, token)
}

export const removeSavedMultiSignInToken = () => {
  return localStorage.removeItem(MULTI_SIGN_IN_TOKEN_LOCALSTORAGE_KEY)
}

export const signOut = async () => {
  try {
    await axios.delete(route("merchant.signout"), {
      data: {
        multi_sign_in_token: getSavedMultiSignInToken(),
      },
    })

    removeSavedMultiSignInToken()

    window.FrontChat("shutdown", { clearSession: true })

    window.location.reload()
  } catch (err) {
    notifications.error({
      message: err.message,
    })

    throw err
  }
}
