<script>
  import link from "@actions/use-link"

  export let inertia = {}
  export let href
  export let disabled = false
  export let external = false
  export let forceSelf = false // Force an external link to open in the same tab
  export let hard = false
  export let type = "stroke"
  export { className as class }

  let className = ""

  const types = {
    accent: "text-accent-base",
    stroke: "text-stroke",
    underline: "underline",
  }
</script>

{#if href}
  <a
    on:click
    on:mouseenter
    on:mouseleave
    on:pointerenter
    on:pointerleave
    on:pointerdown
    on:pointerup
    on:focus
    on:blur
    use:link={{
      apply: !external && !hard,
      ...inertia,
    }}
    {...$$restProps}
    {href}
    class="{types[type]} {className}"
    target={external && !forceSelf ? "_blank" : "_self"}
    rel={external && "nofollow noreferrer"}
  >
    <slot />
  </a>
{:else}
  <button
    type="button"
    {disabled}
    {...$$restProps}
    on:click
    on:mouseenter
    on:mouseleave
    on:pointerenter
    on:pointerleave
    on:pointerdown
    on:pointerup
    on:focus
    on:blur
    class:opacity-75={disabled}
    class:pointer-events-none={disabled}
    class="{types[type]} {className}"
  >
    <slot />
  </button>
{/if}
