import { MenuItem } from "@layouts/categories/index"

export const Entry: MenuItem = {
  name: "Entry",
  path: "furniture.entry",
  children: [
    {
      name: "Coat Racks & Umbrella Stands",
      path: "furniture.entry.coat-racks-umbrella-stands",
    },
    {
      name: "Console & Center Tables",
      path: "furniture.entry.console-center-tables",
    },
    {
      name: "Storage Furniture & Benches",
      path: "furniture.entry.storage-furniture-benches",
    },
  ],
}

export const Benches: MenuItem = {
  name: "Benches",
  path: "furniture.benches",
}

export const Office: MenuItem = {
  name: "Office",
  path: "furniture.office",
  children: [
    {
      name: "Storage & Cabinets",
      path: "furniture.office.storage-cabinets",
    },
    {
      name: "Bookcases & Etageres",
      path: "furniture.office.bookcases-etageres",
    },
    {
      name: "Desk Chairs",
      path: "furniture.office.desk-chairs",
    },
    {
      name: "Desks",
      path: "furniture.office.desks",
    },
  ],
}

export const LivingRoom: MenuItem = {
  name: "Living Room",
  path: "furniture.living-room",
  children: [
    {
      name: "Chairs",
      path: "furniture.living-room.chairs",
    },
    {
      name: "Coffee Tables",
      path: "furniture.living-room.coffee-tables",
    },
    {
      name: "Side Tables",
      path: "furniture.living-room.side-tables",
    },
    {
      name: "Chaise Lounges & Daybeds",
      path: "furniture.living-room.chaise-lounges-daybeds",
    },
    {
      name: "Sectionals",
      path: "furniture.living-room.sectionals",
    },
    {
      name: "Sofas & Loveseats",
      path: "furniture.living-room.sofas-loveseats",
    },
    {
      name: "Cabinets, Hutches & Chests",
      path: "furniture.living-room.cabinets-hutches-chests",
    },
    {
      name: "Console Tables",
      path: "furniture.living-room.console-tables",
    },
    {
      name: "Media Storage",
      path: "furniture.living-room.media-storage",
    },
    {
      name: "Ottomans, Poufs & Stools",
      path: "furniture.living-room.ottomans",
    },
  ],
}

export const DiningRoom: MenuItem = {
  name: "Dining Room",
  path: "furniture.dining-room",
  children: [
    {
      name: "Buffets & Sideboards",
      path: "furniture.dinging-room.buffets-sideboards",
    },
    {
      name: "Dining Tables",
      path: "furniture.dining-room.dining-tables",
    },
    {
      name: "Dining Chairs",
      path: "furniture.dining-room.dining-chairs",
    },
    {
      name: "Barstools & Counter Stools",
      path: "furniture.dining-room.barstools-counter-stools",
    },
    {
      name: "Bar Carts & Cabinets",
      path: "furniture.dining-room.bar-carts-cabinets",
    },
  ],
}

export const Bedroom: MenuItem = {
  name: "Bedroom",
  path: "furniture.bedroom",
  children: [
    {
      name: "Vanities & Vanity Stools",
      path: "furniture.bedroom.vanities-vanity-stools",
    },
    {
      name: "Nightstands",
      path: "furniture.bedroom.nightstands",
    },
    {
      name: "Dressers & Armoires",
      path: "furniture.bedroom.dressers-armoires",
    },
    {
      name: "Headboards",
      path: "furniture.bedroom.headboards",
    },
    {
      name: "Beds",
      path: "furniture.bedroom.beds",
      children: [
        {
          name: "Day Beds",
          path: "furniture.bedroom.beds.day-beds",
        },
        {
          name: "Twin Beds",
          path: "furniture.bedroom.beds.twin-beds",
        },
        {
          name: "Full Beds",
          path: "furniture.bedroom.beds.full-beds",
        },
        {
          name: "Queen Beds",
          path: "furniture.bedroom.beds.queen-beds",
        },
        {
          name: "California King Beds",
          path: "furniture.bedroom.beds.california-king-beds",
        },
        {
          name: "Eastern King/King Beds",
          path: "furniture.bedroom.beds.eastern-kingking-beds",
        },
      ],
    },
  ],
}
