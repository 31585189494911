import { MenuItem } from "@layouts/categories/index"

export const Pattern: MenuItem = {
  name: "Pattern",
  path: "fabric",
  children: [
    {
      name: "Abstract",
      routeParams: {
        categories: ["fabric"],
        patterns: ["abstract"],
      },
    },
    {
      name: "Animal",
      routeParams: {
        categories: ["fabric"],
        patterns: ["animal"],
      },
    },
    {
      name: "Botanical & floral",
      routeParams: {
        categories: ["fabric"],
        patterns: ["botanical & floral"],
      },
    },
    {
      name: "Chinoiserie",
      routeParams: {
        categories: ["fabric"],
        patterns: ["chinoiserie"],
      },
    },
    {
      name: "Damask",
      routeParams: {
        categories: ["fabric"],
        patterns: ["damask"],
      },
    },
    {
      name: "Geometric",
      routeParams: {
        categories: ["fabric"],
        patterns: ["geometric"],
      },
    },
    {
      name: "Plaid/check",
      routeParams: {
        categories: ["fabric"],
        patterns: ["plaid", "check"],
      },
    },
    {
      name: "Solid",
      routeParams: {
        categories: ["fabric"],
        patterns: ["solid"],
      },
    },
    {
      name: "Stripes",
      routeParams: {
        categories: ["fabric"],
        patterns: ["stripes"],
      },
    },
  ],
}

export const Material: MenuItem = {
  name: "Material",
  path: "fabric",
  children: [
    {
      name: "Bouclé",
      routeParams: {
        categories: ["fabric"],
        materials: ["boucle"],
      },
    },
    {
      name: "Chenile",
      routeParams: {
        categories: ["fabric"],
        materials: ["chenile"],
      },
    },
    {
      name: "Leather/suede",
      routeParams: {
        categories: ["fabric"],
        materials: ["leather", "suede"],
      },
    },
    {
      name: "Linen",
      routeParams: {
        categories: ["fabric"],
        materials: ["linen"],
      },
    },
    {
      name: "Mohair",
      routeParams: {
        categories: ["fabric"],
        materials: ["mohair"],
      },
    },
    {
      name: "Print",
      routeParams: {
        categories: ["fabric"],
        materials: ["print"],
      },
    },
    {
      name: "Sheer",
      routeParams: {
        categories: ["fabric"],
        materials: ["sheer"],
      },
    },
    {
      name: "Texture",
      routeParams: {
        categories: ["fabric"],
        materials: ["texture"],
      },
    },
    {
      name: "Velvet",
      routeParams: {
        categories: ["fabric"],
        materials: ["velvet"],
      },
    },
  ],
}

export const EndUse: MenuItem = {
  name: "End use",
  path: "fabric",
  children: [
    {
      name: "Drapery",
      routeParams: {
        categories: ["fabric"],
        end_uses: ["drapery"],
      },
    },
    {
      name: "Multipurpose",
      routeParams: {
        categories: ["fabric"],
        end_uses: ["multipurpose"],
      },
    },
    {
      name: "Upholstery",
      routeParams: {
        categories: ["fabric"],
        end_uses: ["upholstery"],
      },
    },
  ],
}
