<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import Card from "@objects/Card.svelte"
  import Icon from "@components/Icon.svelte"
  import { toHuman } from "@helpers/formatters/currency"
  import LineItem from "@components/Collection/Sidebar/LineItem.svelte"
  import Button from "@components/Button.svelte"
  import { openModal } from "svelte-modals"
  import CouponDialog from "@components/dialogs/CouponDialog/CouponDialog.svelte"
  import feature from "@store/feature"
  import { createEventDispatcher } from "svelte"
  import ShareCollectionDialog from "@components/dialogs/ShareCollectionDialog/ShareCollectionDialog.svelte"
  import plural from "@helpers/plural"
  import notifications from "@store/notifications"
  import { collectionsStore } from "@store/collections"

  export let collection
  export let subtotals

  let className
  export { className as class }

  const dispatch = createEventDispatcher()

  let isRemovingUnpurchasableProducts = false
  let didRemoveUnpurchasableProducts = false

  $: checkoutButtonDisabled = !collection.can.checkout
  $: shareButtonDisabled = !collection.can.checkout || (!collection.is_public && !collection.can.publish)
  $: couponModalButtonText = collection.activeCoupon ? "Edit coupon" : "Create coupon"
  $: couponModalButtonDisabled = collection.products_count < 1 || collection.is_managed_by_oauth_client
  $: obscureWholesalePrice = subtotals.wholesale === undefined
  $: obscureMerchantPrice = subtotals.merchant === undefined
  $: obscureEstimatedProfitFromCustomerCheckout = subtotals.estimated_profit_from_customer_checkout === undefined
  $: canOfferCustomerCoupon = !feature.activated("firms-plus/disable-customer-coupon-use")

  const openCouponDialog = () => {
    if (!canOfferCustomerCoupon) {
      notifications.error({ message: "Your account is not eligible to offer coupons to customers." })
      return
    }

    openModal(CouponDialog, {
      collection,
    })
  }

  const dispatchDesignerCheckoutStarted = () => {
    dispatch("designerCheckoutStarted")
  }

  const shareWithAClient = () => {
    openModal(ShareCollectionDialog, { collection })
  }

  /**
   * It's important to note that we don't need to explicitly trigger a refresh of
   * the subtotals. This is because subtotals always exclude unpurchasable products.
   */
  const removeUnpurchasableProducts = async () => {
    if (
      !confirm(
        `Are you sure you want to remove ${collection.unpurchasable_products_count} discontinued ${plural(
          "products",
          collection.unpurchasable_products_count,
        )} from ${collection.name}? This can't be undone.`,
      )
    ) {
      return
    }

    isRemovingUnpurchasableProducts = true

    try {
      const res = await axios.delete(route("merchant.collections.unavailable-products.destroy", collection))
      const removedProductIds = res.data

      notifications.success({
        message: `Removed ${collection.unpurchasable_products_count} discontinued ${plural(
          "products",
          collection.unpurchasable_products_count,
        )} from ${collection.name}`,
      })

      dispatch("removedUnavailableProducts", {
        removedProductIds,
      })

      didRemoveUnpurchasableProducts = true
    } catch (err) {
      notifications.error({ message: "Couldn't remove discontinued products, please try again later." })

      throw err
    } finally {
      isRemovingUnpurchasableProducts = false
    }

    await collectionsStore.refresh()
  }
</script>

<Card type="canvas" size="none" class="border p-6 {className}">
  {#if collection.unpurchasable_products_count > 0 && !collection.is_managed_by_oauth_client && !didRemoveUnpurchasableProducts}
    <div role="alert" class="bg-red-500 px-6 py-5 -mx-6 -mt-6 mb-6 flex flex-col gap-2">
      <p class="text-xs font-bold text-white">
        {collection.unpurchasable_products_count}
        {plural("products", collection.unpurchasable_products_count)} in this collection
        {collection.unpurchasable_products_count === 1 ? "has" : "have"} been discontinued and
        {collection.unpurchasable_products_count === 1 ? "is" : "are"} no longer available for purchase.
      </p>

      <Button
        size="tiny"
        type="destructive"
        class="self-end"
        on:click={removeUnpurchasableProducts}
        disabled={isRemovingUnpurchasableProducts}
      >
        {isRemovingUnpurchasableProducts ? "Removing…" : "Remove all"}
      </Button>
    </div>
  {/if}

  <section>
    <header class="flex items-center gap-2 mb-4">
      <Icon name="shopping-cart-2" weight="line" class="text-xl -mt-1" />
      <h3 class="text-xl">Designer checkout</h3>
    </header>

    <dl class="flex flex-col gap-4">
      {#if subtotals.wholesale}
        <LineItem
          title="Wholesale subtotal"
          value={toHuman(subtotals.wholesale)}
          obscureValue={obscureWholesalePrice}
        />
      {:else}
        <LineItem title="Designer subtotal" value={toHuman(subtotals.merchant)} obscureValue={obscureMerchantPrice} />
      {/if}

      {#if subtotals.processing_fee}
        <LineItem title="Firms+ fee" value={toHuman(subtotals.processing_fee)}>
          <div slot="tooltipContent" class="font-sans">
            Standard {parseFloat(subtotals.processing_fee_percentage)}% Firms+ processing&nbsp;fee.
          </div>
        </LineItem>
      {/if}

      {#if subtotals.ach_discounted_processing_fee}
        <p class="uppercase text-xs opacity-50 -my-2.5">or</p>

        <LineItem title="ACH Firms+ fee" value={toHuman(subtotals.ach_discounted_processing_fee)}>
          <div slot="tooltipContent" class="font-sans">
            Discounted {parseFloat(subtotals.ach_discounted_processing_fee_percentage)}% Firms+ processing fee (if paid
            via ACH).
          </div>
        </LineItem>
      {/if}

      {#if subtotals.shipping_is_collected_on_delivery}
        <LineItem title="Shipping" value="COD">
          <div slot="tooltipContent" class="font-sans">
            The exact cost of shipping for this collection will be collected after delivery.
          </div>
        </LineItem>
      {:else}
        <LineItem title="Est. shipping" value={toHuman(subtotals.estimated_receiving_warehouse_shipping)}>
          <div slot="tooltipContent" class="font-sans">
            Estimated shipping is based on shipping to a receiver which has a warehouse and a dock. Shipping prices to a
            residential address will increase the cost of shipping.
          </div>
        </LineItem>
      {/if}
    </dl>

    <footer class="flex gap-2 mt-4 justify-end">
      <Button
        href={route("merchant.checkout.cart.create", collection)}
        on:click={dispatchDesignerCheckoutStarted}
        size="small"
        type="accent"
        disabled={checkoutButtonDisabled}
        >Check out
      </Button>
    </footer>
  </section>

  {#if !feature.activated("firms-plus/disable-public-collections")}
    <hr class="my-6" />

    <section class="flex flex-col">
      <header class="flex items-center gap-2 mb-4">
        <Icon name="user-shared" weight="line" class="text-xl -mt-1" />
        <h3 class="text-xl">Share collection</h3>
      </header>

      <dl class="flex flex-col gap-4">
        <LineItem title="Retail subtotal" value={toHuman(subtotals.retail + subtotals.estimated_residential_shipping)}>
          <div slot="tooltipContent" class="font-sans">
            Includes retail price of items and standard shipping. Customers may select white glove shipping during
            checkout for an additional charge.
          </div>
        </LineItem>

        {#if collection.activeCoupon}
          <LineItem
            title="Coupon code"
            value={collection.activeCoupon.code}
            valueClass="bg-neutral-50 px-1 border text-xs font-mono align-[1px]"
          />

          <LineItem title="Coupon discount %" value="{collection.activeCoupon.percent_off}%" />

          <LineItem title="Est. coupon discount" value={toHuman(-subtotals.coupon_discount)} />
        {/if}

        <LineItem
          title="Potential profit"
          value="+{toHuman(subtotals.estimated_profit_from_customer_checkout)}"
          obscureValue={obscureEstimatedProfitFromCustomerCheckout}
          valueClass="text-green-600 font-semibold"
        />
      </dl>

      {#if collection.activeCoupon && !collection.activeCoupon.is_valid}
        <div role="alert" class="bg-red-500 px-3 py-2 mt-4">
          <p class="text-xs font-bold text-white">
            This coupon is invalid. Update its discount percentage to reactivate it.
          </p>
        </div>
      {/if}

      <footer class="flex gap-2 mt-4 justify-end">
        {#if canOfferCustomerCoupon}
          <Button on:click={openCouponDialog} size="small" type="outline" disabled={couponModalButtonDisabled}>
            {couponModalButtonText}
          </Button>
        {/if}

        <Button on:click={shareWithAClient} size="small" type="accent" disabled={shareButtonDisabled}>Share</Button>
      </footer>
    </section>
  {/if}
</Card>
