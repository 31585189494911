<script>
  export let id
  export let name
  export let value = ""
  export let error = false
  export let placeholder = ""
  export let readonly = false
  export let size = "base"
  export { className as class }

  let className = ""
  let element

  const Sizes = {
    none: "",
    base: "c-textarea--base [ px-5 py-3 ]",
  }
</script>

<textarea
  data-testid="textarea"
  {id}
  {name}
  {placeholder}
  {readonly}
  bind:value
  bind:this={element}
  on:keydown
  on:keyup
  on:blur
  on:focus
  on:input
  class:c-textarea--error={error}
  class:contrast-more:border-neutral-600={!error}
  class:border-red-500={error}
  class:c-textarea--readonly={readonly}
  class:cursor-not-allowed={readonly}
  class:bg-neutral-100={readonly}
  class:bg-canvas={!readonly}
  class="
    c-textarea
    [ block w-full rounded ]
    {Sizes[size]}
    [ placeholder-neutral-500 ring-eggplant-700 ]
    {className}
  "
/>
