<script>
  import { route } from "ziggy-js"
  import Button from "@components/Button.svelte"
  import Pill from "@components/Pill.svelte"
  import { designer } from "@store/auth"

  export let product

  export let pillSize = "tiny"

  export let buttonSize = "tiny"

  export let sample = false

  let className = ""
  export { className as class }

  export let leadTimeUnknownClasses = ""

  let inquireHref

  $: inquireHref = (() => {
    const details = {
      subject: "Product Lead Time Check",
      body: `Hi there,

I would like to check the lead time for ${route("merchant.products.show", [
        product,
        product.slug,
      ])}, can you please check with the vendor, and let me know as soon as possible?

Thanks,
${$designer.full_name}`,
    }

    const detailsString = Object.keys(details)
      .map((key) => {
        const val = details[key]
        return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
      })
      .join("&")

    return `mailto:info@onsidedoor.com?${detailsString}`
  })()
</script>

<div class="flex gap-2 {className}" data-testid="ProductAvailability">
  {#if !product.is_purchasable}
    <Pill type="destructive" size={pillSize}>Discontinued</Pill>
  {:else if sample}
    <Pill type="neutral" size={pillSize}>Sample</Pill>

    {#if product.sample_description}
      <Pill type="neutral" size={pillSize}>{product.sample_description}</Pill>
    {/if}
  {:else if product.inventory_remaining > 0}
    <Pill type="neutral" size={pillSize}>
      In stock:

      {#if product.inventory_remaining >= 100}
        100+
      {:else}
        {product.inventory_remaining}
      {/if}
    </Pill>
  {:else if product.made_to_order && product.estimated_lead_time}
    <Pill type="neutral" size={pillSize}>
      Made to order in {product.estimated_lead_time}

      <div slot="tooltipContent">
        Vendor-provided estimate of how long this item takes to make after an order is placed. Not a guaranteed date,
        subject to change.
      </div>
    </Pill>
  {:else if product.estimated_lead_time}
    <Pill type="neutral" size={pillSize}>
      Expected in {product.estimated_lead_time}

      <div slot="tooltipContent">
        Vendor-provided estimate of when they expect this item back in stock. Not a guaranteed date, subject to change.
      </div>
    </Pill>
  {:else}
    <div class="flex gap-2 items-center {leadTimeUnknownClasses}">
      {#if product.made_to_order}
        <Pill type="neutral" size={pillSize}>Made to order</Pill>
      {:else}
        <Pill type="neutral" size={pillSize}>Lead time unknown</Pill>
      {/if}

      <Button type="accent" size={buttonSize} class="ml-auto" href={inquireHref} external>Inquire</Button>
    </div>
  {/if}
</div>
