import { page } from "@inertiajs/svelte"
import { derived, get, readable } from "svelte/store"

const features = derived(page, ($page) => $page.props.features || {})

function createFeatureStore() {
  const { subscribe } = readable(features)

  return {
    subscribe,

    activated(name) {
      return get(features)[name]
    },
  }
}

const store = createFeatureStore()

export default store
