<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import plural from "@helpers/plural"
  import { toHuman } from "@helpers/formatters/currency"
  import Button from "@components/Button.svelte"
  import notifications from "@store/notifications"
  import Dialog from "@objects/Dialog.svelte"
  import DialogBody from "@objects/DialogBody.svelte"
  import Card from "@objects/Card.svelte"
  import { closeAllModals, closeModal, openModal } from "svelte-modals"
  import CouponMoveProductsDialog from "@components/dialogs/CouponDialog/MoveProductsDialog.svelte"
  import DialogActions from "@components/dialogs/CouponDialog/DialogActions.svelte"
  import Analytics from "@services/Analytics"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"

  export let isOpen
  export let onClose
  export let coupon
  export let collection
  export let products
  export let attemptedPercentOff

  let processing

  async function moveProducts() {
    openModal(CouponMoveProductsDialog, {
      coupon,
      collection,
      products,
      attemptedPercentOff,
      onClose: () => {
        if (onClose && typeof onClose === "function") {
          onClose()
        }
      },
    })
  }

  async function removeProducts() {
    if (!confirm(`Are you sure you want to remove all invalid products from this collection?`)) {
      return
    }

    try {
      processing = true
      await axios.delete(route("merchant.collections.selections.bulk.destroy", collection), {
        data: {
          product_ids: products.map((product) => product.id),
        },
      })

      notifications.success({ message: "Products removed from the collection." })

      Analytics.track("Merchant removed invalid products from a collection to facilitate a coupon discount amount", {
        // A user can arrive at the InvalidProductsDialog in both cases where they're creating
        // a new coupon or trying to update the amount of an existing coupon.
        couponId: coupon?.id,
        collectionId: collection.id,
        percentOff: attemptedPercentOff,
      })

      if (onClose && typeof onClose === "function") {
        onClose()
      }

      closeModal()
    } catch (e) {
      console.error(e)
      notifications.error({ message: "Products could not be removed from the collection." })
    } finally {
      processing = false
    }
  }
</script>

{#if isOpen}
  <Dialog on:close={closeAllModals}>
    <DialogBody class="shadow-xl md:max-w-2xl w-full">
      <Card size="modal" type="canvas" class="space-y-8">
        <DialogHeader
          title="Margin too low"
          subtitle="{products.length}
            {plural('product', products.length)}
            {products.length === 1 ? 'has' : 'have'} a profit margin too low for the desired discount amount"
        />

        <p>
          The following products do not offer enough commission to create your coupon for this collection – You can
          remove them from this collection, move them to a new collection, or go back to the previous step to adjust the
          discount amount.
        </p>

        <div class="max-h-[300px] overflow-scroll">
          <table class="w-full">
            <thead>
              <tr>
                <th colspan="2">Product</th>
                <th class="text-right">Retail&nbsp;Price</th>
                <th class="text-right">Profit&nbsp;Margin</th>
              </tr>
            </thead>
            <tbody>
              {#each products as product (product.id)}
                <tr>
                  <td><img src={product.featured_image.url} alt="" class="h-14 w-14" /></td>
                  <td><span>{product.selection.name || product.name}</span></td>
                  <td class="text-right"><span>{toHuman(product.retail_price)}</span></td>
                  <td class="text-right"><span>{product.profit_percent}% Profit</span></td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>

        <DialogActions>
          <Button on:click={removeProducts} type="destructive" disabled={processing}>Remove Products</Button>
          <Button on:click={moveProducts} disabled={processing}>Move Products</Button>
        </DialogActions>
      </Card>
    </DialogBody>
  </Dialog>
{/if}
