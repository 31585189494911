<script>
  import { createEventDispatcher } from "svelte"

  export let id
  export let name
  export let value = ""
  export let error = false
  export let type = "text"
  export let placeholder = ""
  export let readonly = false
  export let applyReadonlyStyles = true
  export let autocomplete = "on"
  export let size = "base"
  export { className as class }

  let className = ""
  let element

  const Sizes = {
    none: "",
    base: "c-input--base [ px-5 py-3  ]",
  }

  const dispatch = createEventDispatcher()

  function handleInput(event) {
    value = event.target.value
    dispatch("input", { value })
  }

  export const focus = () => element.focus()
</script>

<input
  data-testid="input"
  {...$$restProps}
  {id}
  {name}
  {value}
  {type}
  {placeholder}
  {readonly}
  {autocomplete}
  bind:this={element}
  on:keydown
  on:keyup
  on:blur
  on:change
  on:focus
  on:input={handleInput}
  class:c-input--error={error}
  class:contrast-more:border-neutral-600={!error}
  class:border-red-500={error}
  class:c-input--readonly={readonly && applyReadonlyStyles}
  class:cursor-not-allowed={readonly && applyReadonlyStyles}
  class:bg-neutral-100={readonly && applyReadonlyStyles}
  class:bg-canvas={!readonly || !applyReadonlyStyles}
  class="
    c-input block w-full border placeholder-neutral-500 ring-eggplant-700
    {Sizes[size]}
    {className}
  "
/>
