import { inertia } from "@inertiajs/svelte"

export default (
  node,
  options = {
    apply: true,
  },
) => {
  if (!options.apply) {
    return {}
  }

  inertia(node, options)

  return {
    update() {
      inertia(node, options)
    },
  }
}
