<script>
  import { route } from "ziggy-js"
  import { page } from "@inertiajs/svelte"
  import Link from "@components/Link.svelte"
  import Icon from "@components/Icon.svelte"

  let isVisible = false
  let rootEl = null

  const show = () => {
    isVisible = true
  }
  const hide = () => {
    isVisible = false
  }

  const handlePointerEnter = (e) => {
    // Only handle the pointer enter event if it's actually a mouse. Touch
    // events will be handled by the focus/click events.
    if (e.pointerType !== "mouse") {
      return
    }
    show()
  }

  const handleFocus = () => {
    // "Delay" required to prevent showing before the click event handler fires
    // on Android. Note that Svelte's `tick` doesn't work here.
    setTimeout(show, 0)
  }

  const handleBlur = (e) => {
    // Stay open if blurred to a child of this category
    if (!rootEl.contains(e.relatedTarget)) {
      hide()
    }
  }

  const handleClick = (e) => {
    if (isVisible) {
      hide()
    } else {
      e.preventDefault()
      show()
    }
  }
</script>

<div on:mouseleave={hide} bind:this={rootEl}>
  <Link
    href={route("merchant.vendors.index")}
    on:click={handleClick}
    on:pointerenter={handlePointerEnter}
    on:focus={handleFocus}
    on:blur={handleBlur}
    class="block py-3 px-4 text-center shrink border-b-2 border-transparent group-hover:border-accent-base"
  >
    Vendors
  </Link>

  <div class="absolute left-0 lg:left-auto pt-1 w-full lg:w-auto" class:hidden={!isVisible} class:block={isVisible}>
    <div class="bg-white border shadow flex flex-col py-7 px-8">
      <div class="flex flex-row justify-between items-center mb-2">
        <h4 class="text-2xl">Top vendors</h4>

        <Link
          on:click={handleClick}
          on:blur={handleBlur}
          href={route("merchant.vendors.index")}
          class="-mt-1.5 flex items-center gap-1 opacity-60"
        >
          <span>View all {$page.props.activeVendorsCount}</span>
          <Icon name="arrow-right" weight="line" />
        </Link>
      </div>

      <ul class="columns-2 sm:columns-3 md:columns-4 lg:columns-2">
        {#each $page.props.featuredVendors as vendor}
          <li>
            <Link
              on:click={handleClick}
              on:blur={handleBlur}
              href={route("merchant.vendors.show", [vendor, vendor.slug])}
              class="block py-1 pr-6">{vendor.name}</Link
            >
          </li>
        {/each}
      </ul>
    </div>
  </div>
</div>
