<script>
  import link from "@actions/use-link"

  export let inertia = {}

  /**
   * @type {string}
   */
  export let href = ""

  export let type = "primary"

  export let size = "base"

  export let excludeBaseStyle = false

  /**
   * @type {string|null}
   */
  export let role = null

  /**
   * @type {boolean}
   */
  export let disabled = false

  /**
   * Open a new tab when the button is clicked. This only works when the `href` property is set.
   *
   * @type {boolean}
   */
  export let external = false

  /**
   * Set to true for `external` links that should open in the same tab
   *
   * @type {boolean}
   */
  export let forceSelf = false

  /**
   * Visit the URL without using Inertia's redirect.
   *
   * @type {boolean}
   */
  export let hard = false

  /**
   * Use the button as to trigger form submits.
   *
   * @type {boolean}
   */
  export let submit = false

  /**
   * @type {number}
   */
  export let tabindex = undefined

  export let testid = "button"

  export {
    /**
     * @type {string}
     */
    className as class,
  }

  let className = ""

  const baseStyle = `border inline-flex items-center justify-center uppercase outline-none focus-visible:ring-1 transition-all duration-100 ease-in-out`

  const sizes = {
    none: "",
    tiny: "c-button--tiny px-2 py-0.5 text-xs",
    small: "c-button--small px-3 py-1 font-semibold text-sm",
    base: "c-button--base px-5 py-2 font-semibold",
    large: "c-button--large px-8 py-3 font-semibold text-lg w-full flex",
  }

  const types = {
    none: "",
    primary:
      "c-button--primary bg-neutral-950 border-neutral-950 text-white hover:bg-neutral-900 hover:border-neutral-900",
    secondary: "bg-neutral-700 border-neutral-700 text-neutral-100 hover:bg-neutral-800 hover:border-neutral-800",
    accent: "bg-eggplant-950 border-eggplant-950 text-eggplant-50 hover:bg-eggplant-900 hover:border-eggplant-900",
    beige: "bg-beige-100 border-transparent hover:bg-beige-200",
    neutral:
      "c-button--neutral bg-neutral-50 border-neutral-50 hover:bg-neutral-100 hover:border-neutral-200 text-neutral-800",
    destructive: "c-button--destructive bg-red-200 border-red-200 text-red-700",
    white: "c-button--white bg-white border-white text-black",
    canvas: "c-button--canvas bg-canvas border-canvas",
    clear: "c-button--clear bg-transparent border-transparent",
    outline: "c-button--outline bg-transparent border-neutral-100 contrast-more:border-neutral-500 hover:bg-neutral-50",
  }
</script>

{#if href}
  <a
    data-testid={testid}
    {href}
    {tabindex}
    on:click
    use:link={{
      apply: !external && !hard,
      ...inertia,
    }}
    aria-disabled={disabled ? "true" : "false"}
    target={external && !forceSelf ? "_blank" : "_self"}
    rel={external ? 'rel="noopener noreferrer"' : ""}
    class="c-button {className} {excludeBaseStyle ? '' : baseStyle} {sizes[size]} {types[type]}"
    class:opacity-50={disabled}
    class:pointer-events-none={disabled}
    role={role || "link"}
  >
    <slot />
  </a>
{:else}
  <button
    data-testid={testid}
    type={submit ? "submit" : "button"}
    {disabled}
    {tabindex}
    on:click
    class="c-button {className} {excludeBaseStyle ? '' : baseStyle} {sizes[size]} {types[type]}"
    class:opacity-50={disabled}
    class:pointer-events-none={disabled}
    role={role || "button"}
  >
    <slot />
  </button>
{/if}
