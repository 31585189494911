<script>
  import { onMount } from "svelte"
  import { getContext } from "./context"

  const frameBounds = (container) => {
    if (container instanceof Window) {
      return {
        top: container.scrollY,
        bottom: container.scrollY + container.innerHeight,
        height: container.innerHeight,
      }
    }

    if (container instanceof HTMLElement) {
      return {
        top: container.scrollTop,
        bottom: container.scrollTop + container.offsetHeight,
        height: container.offsetHeight,
      }
    }

    throw new Error("Container is not an element")
  }

  const scrollContainerTo = (container, item) => {
    if (!container || !item) {
      return
    }

    const frame = frameBounds(container)

    const element = {
      top: item.offsetTop,
      bottom: item.offsetTop + item.offsetHeight,
    }

    if (element.top < frame.top) {
      container.scrollTo({ top: element.top })
    }

    if (element.bottom > frame.bottom) {
      container.scrollTo({
        top: element.bottom - frame.height,
      })
    }
  }

  export let display = (value) => ""
  const { combobox, cursor, handles } = getContext()
  function handleNavigateDown(event) {
    event.preventDefault()
    cursor.next()
    scrollContainerTo($combobox.$options, $cursor.item?.$element)
  }
  function handleNavigateUp(event) {
    event.preventDefault()
    cursor.previous()
    scrollContainerTo($combobox.$options, $cursor.item?.$element)
  }
  function handleEnter(event) {
    event.preventDefault()
    if (!$cursor.item) {
      return
    }
    combobox.select($cursor.item?.value)
  }
  async function handleKeydown(event) {
    if (event.key === "Escape") {
      event.preventDefault()
      $combobox.$input.dispatchEvent(new CustomEvent("escape", { detail: combobox }))
      return
    }
    if (!$combobox.open) {
      combobox.open()
    }
    switch (event.key) {
      case "ArrowDown":
        return handleNavigateDown(event)
      case "ArrowUp":
        return handleNavigateUp(event)
      case "Enter":
        return handleEnter(event)
      default:
        cursor.reset()
    }
  }
  let value
  $: value = $combobox.value ? display($combobox.value) : ""
  onMount(() => {
    $combobox.$input.dispatchEvent(new CustomEvent("mount"))
  })
</script>

<input
  bind:this={$combobox.$input}
  type="text"
  role="combobox"
  {...$$restProps}
  on:escape
  on:mount
  on:keydown
  on:input
  on:keydown={handleKeydown}
  on:focusin={combobox.open}
  on:click={combobox.open}
  {value}
  id={handles.firstOrNew("input")}
/>
