<script>
  import { createEventDispatcher, onMount, onDestroy } from "svelte"

  export let top = "0px"
  export let right = "0px"
  export let bottom = "0px"
  export let left = "0px"
  export { className as class }

  let className = ""
  let container
  let observer

  const dispatch = createEventDispatcher()

  function handleIntersect(entry) {
    dispatch("intersect")
  }

  function handleRelease(entry) {
    dispatch("release")
  }

  onMount(() => {
    const rootMargin = `${top} ${right} ${bottom} ${left}`

    observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            handleIntersect(entry)
          } else {
            handleRelease(entry)
          }
        }),
      { rootMargin },
    )

    observer.observe(container)
  })

  onDestroy(() => {
    observer.unobserve(container)
  })
</script>

<div bind:this={container} class={className}>
  <slot />
</div>
