<script>
  export let open = false

  let isOpen = open
  const toggle = () => (isOpen = !isOpen)
</script>

<div class="o-detail" data-testid="details">
  <slot name="summary" open={isOpen} {toggle} />

  <div class="o-detail__content" class:hidden={!isOpen}>
    <slot />
  </div>
</div>
