<script>
  import { closeAllModals, closeModal } from "svelte-modals"
  import { onMount } from "svelte"
  import axios from "axios"
  import { route } from "ziggy-js"
  import { router } from "@inertiajs/svelte"

  import Button from "@components/Button.svelte"
  import Card from "@objects/Card.svelte"
  import Dialog from "@objects/Dialog.svelte"
  import LoadNextPage from "@objects/LoadNextPage.svelte"
  import IntersectionObserver from "@objects/IntersectionObserver.svelte"
  import SelectionCard from "@components/SelectionCard.svelte"
  import CollectionSidebar from "@components/Collection/Sidebar/CollectionSidebar.svelte"
  import Spinner from "@components/Spinner.svelte"
  import NoCollectionsCallout from "@pages/collections/NoCollectionsCallout.svelte"
  import notifications from "@store/notifications"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"
  import Container from "@objects/Container.svelte"
  import SelectCollectionType from "@components/SelectCollectionType.svelte"

  export let isOpen
  export let collection

  let internalCollection = collection

  let subtotals = null
  let collectionSettings = null
  let initialLoadComplete = false
  let selectionsPage = null
  let windowHeight
  let collectionSidebarHeight

  onMount(() => {
    init()
  })

  const init = async () => {
    try {
      const [collectionRes, selectionsRes] = await Promise.all([
        axios.get(route("merchant.collections.show", internalCollection)),
        axios.get(route("merchant.collections.selections.index", internalCollection)),
      ])

      selectionsPage = selectionsRes.data

      internalCollection = collectionRes.data.collection
      collectionSettings = collectionRes.data.collectionSettings
      subtotals = collectionRes.data.subtotals

      initialLoadComplete = true
    } catch (err) {
      closeModal()

      notifications.error({
        title: "Error previewing collection",
        message: err,
      })
    }
  }

  const refreshCollectionData = async () => {
    try {
      const collectionRes = await axios.get(route("merchant.collections.show", collection))

      collectionSettings = collectionRes.data.collectionSettings
      subtotals = collectionRes.data.subtotals
    } catch (err) {
      notifications.error({
        title: "Error previewing collection",
        message: err,
      })
    }
  }

  const handleSelectionUpdate = (e) => {
    const updatedSelection = e.detail.selection
    const index = selectionsPage.data.findIndex((selection) => {
      return selection.id === updatedSelection.id
    })

    selectionsPage.data[index] = updatedSelection

    refreshCollectionData()
  }

  const handleSelectionDelete = (event) => {
    selectionsPage.data = selectionsPage.data.filter((selection) => {
      return selection.id !== event.detail.selection.id
    })
    refreshCollectionData()
  }

  const handleWindowKeydown = (e) => {
    if (!isOpen) {
      return
    }

    if (e.key === "Escape") {
      closeModal()
    }
  }
  const handleClickViewFullCollection = () => {
    closeAllModals()
    router.visit(route("merchant.collections.show", collection))
  }

  const handleRemovedUnavailableProducts = (e) => {
    const removedProductIds = e.detail.removedProductIds
    collection.products_count = Math.max(0, collection.products_count - removedProductIds.length)

    selectionsPage.data = selectionsPage.data.filter((selection) => {
      return !removedProductIds.includes(selection.product_id)
    })
  }
</script>

<svelte:window on:keydown={handleWindowKeydown} bind:innerHeight={windowHeight} />

{#if isOpen}
  <Dialog on:close={closeAllModals}>
    <Container padding="none" size="max" class="grow">
      <Card type="canvas" class="space-y-8 shadow-xl text-left">
        <DialogHeader title={internalCollection.name}>
          <SelectCollectionType collection={internalCollection} />
        </DialogHeader>

        {#if initialLoadComplete}
          <div class="[ md:flex ]">
            <aside class="md:order-2 mb-8 flex flex-col items-center gap-6 md:mb-0 md:w-[19rem] md:shrink-0 md:ml-12">
              <Button type="accent" class="w-full max-w-[300px]" on:click={handleClickViewFullCollection}
                >View full collection</Button
              >

              <div class="top-6 w-full" class:sticky={collectionSidebarHeight < windowHeight}>
                <div bind:clientHeight={collectionSidebarHeight}>
                  <CollectionSidebar
                    collection={internalCollection}
                    {subtotals}
                    on:designerCheckoutStarted={closeModal}
                    on:removedUnavailableProducts={handleRemovedUnavailableProducts}
                  />
                </div>
              </div>
            </aside>

            <section class="[ w-full ]">
              <div class="[ w-full grid gap-4 xl:gap-y-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 ]">
                {#if selectionsPage}
                  {#each selectionsPage.data as selection (selection.id)}
                    <SelectionCard
                      collection={internalCollection}
                      {collectionSettings}
                      {selection}
                      product={selection.product}
                      on:updated={handleSelectionUpdate}
                      on:deleted={handleSelectionDelete}
                      enableEditing={!internalCollection.is_managed_by_oauth_client}
                    />
                  {/each}
                {/if}
              </div>

              {#if selectionsPage && selectionsPage.data.length === 0}
                <div class="[ my-12 ]">
                  <NoCollectionsCallout
                    title="This collection is empty"
                    overrideBrowseAction={true}
                    on:browse={closeAllModals}
                  />
                </div>
              {/if}
            </section>
          </div>

          <div class="[ mt-36 ]">
            <LoadNextPage bind:items={selectionsPage} let:isLoading let:onLastPage let:next>
              <IntersectionObserver on:intersect={next} bottom="50%">
                <div class="[ flex justify-center ]">
                  {#if onLastPage}
                    <p class="text-sm text-center italic">All the products have been loaded</p>
                  {:else if isLoading}
                    <p class="[ text-sm text-center italic ]">Loading more products…</p>
                  {:else}
                    <Button on:click={next} type="neutral">Load more products</Button>
                  {/if}
                </div>
              </IntersectionObserver>
            </LoadNextPage>
          </div>
        {:else}
          <div class="flex items-center justify-center space-x-2">
            <Spinner />
            <span class="sr-only">Loading collection…</span>
          </div>
        {/if}
      </Card>
    </Container>
  </Dialog>
{/if}
