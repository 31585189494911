<script>
  import dayjs from "dayjs"
  import { router } from "@inertiajs/svelte"
  import { closeAllModals, openModal } from "svelte-modals"
  import { toHuman } from "@helpers/formatters/currency"
  import { toHuman as dateToHuman } from "@helpers/formatters/datetime"
  import { designer, merchant } from "@store/auth"
  import Button from "@components/Button.svelte"
  import Pill from "@components/Pill.svelte"
  import Image from "@components/Image.svelte"
  import ThumbnailGallery from "@components/ThumbnailGallery.svelte"
  import Link from "@components/Link.svelte"
  import Icon from "@components/Icon.svelte"
  import ProductAvailability from "@components/ProductAvailability.svelte"
  import { open as openSelectionManager } from "@components/SelectionManager.svelte"
  import { collectionsStore } from "@store/collections"
  import selectionManagement from "@store/selectionManagement"
  import asset from "@helpers/asset"
  import notifications from "@store/notifications"
  import CreateCollectionDialog from "@components/dialogs/CreateCollectionDialog/CreateCollectionDialog.svelte"
  import { getUnitPriceSuffix, hasValidSourceUrl } from "@helpers/models/product"

  export let product

  $: merchantPriceTitle = product.merchant_price
    ? undefined
    : `Add your resale certificate to view ${$merchant.type.toLowerCase()} pricing!`
  $: unitPriceSuffix = getUnitPriceSuffix(product)
  $: areSamplesAvailable = product.sample_price_in_cents !== null

  let prettyProductUpdatedAt = ""
  $: {
    const now = dayjs()
    const productUpdated = dayjs(product.updated_at)
    const updatedDaysAgo = now.diff(productUpdated, "day")
    if (updatedDaysAgo > 7) {
      prettyProductUpdatedAt = dateToHuman(product.updated_at)
    } else {
      prettyProductUpdatedAt = now.to(product.updated_at)
    }
  }

  const ClearanceIndicator = {
    badge: "bg-red-500",
    text: "text-red-500",
  }

  let featuredImage = product.images.find((image) => image.is_featured) || product.images[0]
  let colors = product.colors.map((material) => material.name).join(", ")
  let materials = product.materials
    .filter((material) => material.name !== "")
    .map((material) => material.name)
    .join(", ")
  let patterns = product.patterns.map((pattern) => pattern.name).join(", ")
  let endUses = product.end_uses.map((endUse) => endUse.name).join(", ")

  function addToCollection(e) {
    if ($collectionsStore.collections.length === 0) {
      openModal(CreateCollectionDialog, {
        onCreate: (collection) => {
          selectionManagement.setProduct(product)
          selectionManagement.addProductToCollection(collection)

          notifications.success({
            image: asset($selectionManagement.product.featured_image.url, { width: 150 }),
            title: "Created collection",
            message: `<strong>${collection.name}</strong> was created with product <strong>${$selectionManagement.product.name}</strong>.`,
          })
        },
      })
    } else {
      openSelectionManager({
        product,
        triggerElement: e.currentTarget,
      })
    }
  }

  const visitPermalink = () => {
    closeAllModals()
    router.visit(product.links.show_path)
  }
</script>

<article class="p-product" data-testid="ProductPage">
  <header class="[ block lg:hidden mb-8 ]">
    {#if product.has_clearance_wholesale_price || product.has_clearance_merchant_price || product.has_clearance_retail_price}
      <div data-testid="ProductPage__clearance-badge" tabindex="-1" class="[ mb-3 ]">
        <div class="[ mb-3 inline-flex items-center text-xs rounded shadow z-content ]">
          <span
            class="[ p-2 rounded rounded-r-none border border-red-500 font-semibold text-white {ClearanceIndicator.badge} ]"
          >
            On Clearance
          </span>

          <span class="[ px-3 py-2 rounded bg-white rounded-l-none border border-red-500 ]">
            For {product.clearance_time_remaining}
          </span>
        </div>
      </div>
    {/if}

    <Link href={product.vendor.links.show_path} class="[ text-2xl font-semibold tracking-tight ]">
      {product.vendor.name}
    </Link>

    <h1 class="[ text-5xl ]">{product.name}</h1>
  </header>

  <section class="[ grid lg:gap-12 lg:grid-cols-2 ]">
    <section class="[ relative shrink-0 md:aspect-[4/3] ]">
      <div class="[ absolute inset-0 z-overlay ]" />

      <Image
        src={featuredImage.url}
        alt="Preview image for {product.name}"
        aspect="square"
        class="rounded max-w-full mx-auto"
        fit="contain"
        width="1024"
      />
    </section>

    <section class="[ flex flex-col ]">
      <header class="[ hidden lg:block ]">
        {#if product.has_clearance_merchant_price || product.has_clearance_retail_price}
          <div tabindex="-1" class="[ mb-3 inline-flex items-center text-xs rounded shadow z-content ]">
            <span
              class="
                [ p-2 rounded rounded-r-none border border-red-500 font-semibold text-white {ClearanceIndicator.badge} ]
              "
            >
              On Clearance
            </span>

            <span class="[ px-3 py-2 rounded bg-white rounded-l-none border border-red-500 ]">
              For {product.clearance_time_remaining}
            </span>
          </div>
        {/if}

        <h1 class="[ text-5xl lg:text-6xl ]">
          {product.name}
        </h1>

        <Link href={product.vendor.links.show_path} class="[ text-xl font-semibold tracking-tight mt-2 ]">
          {product.vendor.name}
        </Link>
      </header>

      <ThumbnailGallery bind:selected={featuredImage} images={product.images} class="[ mt-4 mb-6 ]" />

      <div class="c-product-card__pricing [ mt-6 ]">
        <div class="flex gap-10">
          {#if product.wholesale_price}
            <div data-testid="ProductPage__wholesale-price" class="leading-tight space-y-1">
              <div class="uppercase tracking-wide text-xs font-semibold">Wholesale</div>
              <div
                class="c-product__price c-product__price--wholesale text-2xl sm:text-4xl md:text-5xl font-semibold tracking-tighter flex flex-col"
              >
                <span>
                  {toHuman(product.wholesale_price)}<wbr />{unitPriceSuffix}
                </span>

                {#if product.has_clearance_wholesale_price}
                  <span class="sr-only">
                    On sale from {toHuman(product.original_wholesale_price)}
                  </span>

                  <span class="line-through text-sm {ClearanceIndicator.text}" aria-hidden="true">
                    {toHuman(product.original_wholesale_price)}
                  </span>
                {:else}
                  <span class="text-sm" aria-hidden="true">&nbsp;</span>
                {/if}
              </div>
            </div>
          {/if}

          {#if !product.wholesale_price}
            <div
              data-testid="ProductPage__merchant-price"
              class="leading-tight space-y-1"
              aria-hidden={product.merchant_price ? undefined : "true"}
            >
              <div class="[ uppercase tracking-wide text-xs font-semibold ]">{$merchant.type}</div>
              <div
                class="c-product__price c-product__price--merchant [ text-2xl sm:text-4xl md:text-5xl font-semibold tracking-tighter flex flex-col ]"
                class:blur={!product.merchant_price}
                class:select-none={!product.merchant_price}
                title={merchantPriceTitle}
              >
                <span>
                  {toHuman(product.merchant_price)}<wbr />{unitPriceSuffix}
                </span>

                {#if product.has_clearance_merchant_price}
                  <span class="[ sr-only ]">
                    On sale from {toHuman(product.original_merchant_price)}
                  </span>

                  <span class="[ line-through text-2xl {ClearanceIndicator.text} ]" aria-hidden="true">
                    {toHuman(product.original_merchant_price)}
                  </span>
                {/if}
              </div>
            </div>
          {/if}

          <div class="[ leading-tight space-y-1 ]">
            <div class="[ uppercase tracking-wide text-xs font-semibold ]">Retail</div>
            <div
              class="c-product__price c-product__price--retail [ text-2xl sm:text-4xl md:text-5xl font-semibold tracking-tighter flex flex-col ]"
            >
              <span>
                {toHuman(product.retail_price)}<wbr />{unitPriceSuffix}
              </span>

              {#if product.has_clearance_retail_price}
                <span class="[ sr-only ]">
                  On sale from {toHuman(product.original_retail_price)}
                </span>

                <span class="[ line-through text-2xl {ClearanceIndicator.text} ]" aria-hidden="true">
                  {toHuman(product.original_retail_price)}
                </span>
              {/if}
            </div>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap items-center gap-2">
          {#if product.profit}
            <Pill type="profit" size="small">
              <strong class="font-bold">{toHuman(product.profit)}</strong> profit
            </Pill>
          {/if}

          {#if areSamplesAvailable}
            <Pill type="neutral" size="small">Samples available</Pill>
          {/if}

          <ProductAvailability {product} pillSize="small" buttonSize="small" />
        </div>
      </div>

      <div class="mt-6">
        <Button
          type="primary"
          disabled={!$collectionsStore.initialLoadComplete}
          on:click={addToCollection}
          size="large"
        >
          {$collectionsStore.initialLoadComplete ? `Add to collection` : "Loading collections…"}
        </Button>

        {#if prettyProductUpdatedAt}
          <div class="pt-2 text-lg">
            <p class="opacity-70">Product info updated {prettyProductUpdatedAt}</p>
          </div>
        {/if}
      </div>

      <div class="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mt-4 empty:mt-0">
        {#if window.location.href !== product.links.show_path}
          <Button type="none" size="none" excludeBaseStyle on:click={visitPermalink} class="flex">
            <span class="[ underline ]">View full product info</span>
            <Icon name="arrow-drop-right-line" />
          </Button>
        {/if}

        {#if hasValidSourceUrl(product)}
          <Button
            type="none"
            size="none"
            excludeBaseStyle
            href={product.source_url}
            external
            class="flex"
            testid="ProductPage__view-on-vendor-website"
          >
            <span class="underline">View on vendor website</span>
            <Icon name="arrow-drop-right-line" />
          </Button>
        {/if}
      </div>

      {#if product.links.admin_edit_path}
        <div class="[ mt-10 bg-neutral-50 p-6 px-7 space-y-4 max-w-2xl ]">
          <header>
            <strong class="[ block font-semibold tracking-tight ]">
              Hey {$designer.full_name}, as an admin here's what you can do...
            </strong>

            <p class="[ text-neutral-600 ]">
              Last updated at {dayjs(product.updated_at).format("MMMM DD, YYYY")}.
            </p>
          </header>

          <div>
            <Button
              type="neutral"
              size="small"
              external="true"
              href={product.links.admin_edit_path}
              class="[ font-semibold ]"
            >
              Edit Details
            </Button>
          </div>
        </div>
      {/if}
    </section>
  </section>

  <section class="[ bg-neutral-50 p-8 mt-12 mx-auto max-w-5xl ]">
    <h2 class="[ font-body text-xl tracking-tight mb-4 ]">Product Information</h2>

    <dl class="[ grid md:grid-cols-2 gap-6 ]">
      {#if product.dimensions}
        <div class="[ flex flex-col leading-tight ]">
          <dt class="[ text-base font-semibold mb-1 ]">Dimensions</dt>
          <dd class="[ text-lg font-normal ]">
            {product.dimensions}
          </dd>
        </div>
      {/if}

      {#if product.weight}
        <div class="[ flex flex-col leading-tight ]">
          <dt class="[ text-base font-semibold mb-1 ]">Weight</dt>
          <dd class="[ text-lg font-normal ]">{product.weight}lbs</dd>
        </div>
      {/if}

      {#if colors}
        <div class="[ flex flex-col leading-tight ]">
          <dt class="[ text-base font-semibold mb-1 ]">Color</dt>
          <dd class="[ text-lg font-normal capitalize ]">{colors}</dd>
        </div>
      {/if}

      {#if materials}
        <div class="[ flex flex-col leading-tight ]">
          <dt class="[ text-base font-semibold mb-1 ]">Material</dt>
          <dd class="[ text-lg font-normal capitalize ]">
            {materials}
          </dd>
        </div>
      {/if}

      {#if patterns}
        <div class="[ flex flex-col leading-tight ]">
          <dt class="[ text-base font-semibold mb-1 ]">Pattern</dt>
          <dd class="[ text-lg font-normal capitalize ]">
            {patterns}
          </dd>
        </div>
      {/if}

      {#if endUses}
        <div class="[ flex flex-col leading-tight ]">
          <dt class="[ text-base font-semibold mb-1 ]">End use</dt>
          <dd class="[ text-lg font-normal capitalize ]">
            {endUses}
          </dd>
        </div>
      {/if}
    </dl>
  </section>

  {#if product.description}
    <section class="p-product__description o-prose [ leading-relaxed mx-auto max-w-2xl mt-6 ]">
      {@html product.display_description}
    </section>
  {/if}
</article>
