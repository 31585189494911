import { derived, get } from "svelte/store"
import { defineStore } from "./store"

const CursorConfigDefaults = {
  wrap: true,
}

const defineCursor = (items, uniqueBy, config = CursorConfigDefaults) => {
  const $items = () => get(items)

  return defineStore({
    state: {
      item: null,

      uniqueBy,

      ...config,
    },
    actions: {
      $position({ item }) {
        if (!item) {
          return -1
        }

        return $items().findIndex(($item) => $item[uniqueBy] === item[uniqueBy])
      },

      $onFirst() {
        return this.$position() === 0
      },

      $onLast() {
        return this.$position() + 1 === $items().length
      },

      select(state, item) {
        this.$change({ item })
      },

      reset() {
        if ($items().length > 0) {
          this.$change({ item: $items()[0] })
        } else {
          this.$change({ item: null })
        }
      },

      next({ wrap }) {
        if (!wrap && this.$onLast()) {
          return
        }

        const next = (this.$position() + 1) % $items().length

        this.$change({ item: $items()[next] })
      },

      previous({ wrap }) {
        if (!wrap && this.$onFirst()) {
          return
        }

        const previous = (this.$position() + $items().length - 1) % $items().length

        this.$change({ item: $items()[previous] })
      },
    },
  })
}

export const useCursor = (combobox) =>
  defineCursor(
    derived(combobox, (combobox) => {
      return combobox.options.filter((option) => !option.disabled)
    }),
    "key",
  )
