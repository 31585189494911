<script>
  import { onDestroy, onMount } from "svelte"
  import { derived } from "svelte/store"
  import { getContext } from "./context"
  export let key
  export let value = null
  export let disabled = false
  const { combobox, cursor, handles } = getContext()
  const option = {
    key,
    value,
    disabled,
    $element: null,
  }
  function reject(items, key, value) {
    return items.filter((item) => item[key] !== value)
  }
  function handleClick() {
    if (disabled) {
      return
    }
    combobox.select(value)
  }
  const active = derived(cursor, ({ item }) => {
    return !!item && item.key === key
  })
  function handleMouseenter() {
    if (disabled) {
      return
    }
    cursor.select(option)
  }
  function findOptionIndex(option) {
    return Array.from($combobox.$options.children).findIndex((element) => element === option.$element)
  }
  onMount(() => {
    $combobox.options = [...$combobox.options, option].sort((a, b) => findOptionIndex(a) - findOptionIndex(b))
    cursor.reset()
  })
  onDestroy(() => {
    $combobox.options = reject($combobox.options, "key", key).sort((a, b) => findOptionIndex(a) - findOptionIndex(b))
    cursor.reset()
  })
</script>

<li
  role="option"
  {...$$restProps}
  on:mouseenter={handleMouseenter}
  on:click|preventDefault={handleClick}
  bind:this={option.$element}
  aria-disabled={disabled ? "true" : "false"}
  aria-selected={combobox.selected(value) ? "true" : "false"}
  id={handles.new("option")}
>
  <slot active={$active} selected={combobox.selected(value)} />
</li>
