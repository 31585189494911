export interface CurrencyToHumanOptions {
  stripTrailingZeroes: boolean
  locale?: string
  options?: Intl.NumberFormatOptions
}

/**
 * Returns a string formatted as a currency.
 */
export function toHuman(price: number | string, opts?: CurrencyToHumanOptions) {
  const defaultOpts: CurrencyToHumanOptions = {
    stripTrailingZeroes: false,
    locale: "en-US",
    options: {
      style: "currency",
      currency: "USD",
      maximumFractionDigits:
        opts?.stripTrailingZeroes && parseInt(price as string, 10) === parseFloat(price as string) ? 0 : 2,
    },
  }

  opts = { ...defaultOpts, ...(opts || {}) }

  return new Intl.NumberFormat(opts.locale, opts.options).format(price as number)
}
