<script context="module">
  import { createFloatingActions } from "svelte-floating-ui"
  import { offset, shift } from "svelte-floating-ui/dom"
  import { writable } from "svelte/store"

  import asset from "@helpers/asset"
  import notifications from "@store/notifications"
  import selectionManagement from "@store/selectionManagement"

  let isOpen = writable(false)
  let floatingContentEl
  let onClose = null

  const [floatingRef, floatingContent] = createFloatingActions({
    strategy: "absolute",
    placement: "bottom-start",
    middleware: [offset(3), shift()],
  })

  export const close = () => {
    isOpen.update(() => false)
    if (onClose) {
      onClose()
    }
  }

  export const open = ({ product: newProduct, triggerElement, onClose: newOnClose }) => {
    isOpen.update(() => true)
    if (newOnClose) {
      onClose = newOnClose
    }

    floatingRef(triggerElement)
    floatingContent(floatingContentEl)

    selectionManagement.setProductAndFetchSelectionCollectionIds(newProduct)
  }
</script>

<script>
  import * as focusTrap from "focus-trap"
  import { onMount } from "svelte"
  import Icon from "@components/Icon.svelte"
  import SelectionManagerCollection from "@components/SelectionManagerCollection.svelte"
  import { collectionsStore } from "@store/collections"
  import clickOutside from "@actions/use-click-outside"
  import { openModal } from "svelte-modals"
  import CreateCollectionDialog from "@components/dialogs/CreateCollectionDialog/CreateCollectionDialog.svelte"

  let searchQuery = ""

  let trap

  let isFloatingContentHidden = true

  let sortedCollections = []
  const updateSortedCollections = () => {
    sortedCollections = $collectionsStore.collections.sort((coll1, coll2) => {
      return new Date(coll2.updated_at) - new Date(coll1.updated_at)
    })
  }

  $: {
    if ($isOpen) {
      isFloatingContentHidden = false
      trap.activate()

      updateSortedCollections()
    }
  }

  onMount(() => {
    collectionsStore.refresh()

    trap = focusTrap.createFocusTrap(floatingContentEl, {
      fallbackFocus: floatingContentEl,
      escapeDeactivates: false,
      clickOutsideDeactivates: false,
      allowOutsideClick: true,
    })
  })

  const closeComponent = () => {
    searchQuery = ""
    trap.deactivate()
    close()
  }

  $: filteredCollections = searchQuery
    ? sortedCollections.filter((coll) => {
        return coll.name.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
      })
    : sortedCollections

  const handleWindowKeydown = (e) => {
    if (!$isOpen) {
      return
    }

    if (e.key === "Escape") {
      // Clear query on first escape, exit on second
      if (document.activeElement.nodeName === "INPUT" && searchQuery) {
        searchQuery = ""
      } else {
        closeComponent()
      }
    }
  }

  const beginCreateCollection = () => {
    closeComponent()

    openModal(CreateCollectionDialog, {
      onCreate: (collection) => {
        selectionManagement.addProductToCollection(collection)

        notifications.success({
          image: asset($selectionManagement.product.featured_image.url, { width: 150 }),
          title: "Created collection",
          message: `<strong>${collection.name}</strong> was created with product <strong>${$selectionManagement.product.name}</strong>.`,
        })
      },
    })
  }

  const handleClickOutside = (e) => {
    if ($isOpen) {
      e.detail.originalEvent.preventDefault()
      e.detail.originalEvent.stopPropagation()
      closeComponent()
    }
  }
</script>

<svelte:window on:keydown={handleWindowKeydown} />

<!-- NOTE: This component doesn't contain its own `floatingRef` trigger to open the content. Instead, a reference to a DOM node is passed when this component is opened. This hidden DOM node is to avoid a console error. -->
<div aria-hidden="true" class="hidden" use:floatingRef />

{#if $isOpen}
  <div class="inset-0 fixed bg-linen z-[61] opacity-30 pointer-events-none" />
{/if}

<div
  use:floatingContent
  bind:this={floatingContentEl}
  class="z-[62] w-[260px]"
  class:invisible={isFloatingContentHidden}
  on:outroend={() => (isFloatingContentHidden = true)}
  use:clickOutside
  on:click-outside={handleClickOutside}
>
  {#if $isOpen}
    <div class="bg-white shadow rounded-sm">
      <div class="border-b flex">
        <div class="relative">
          <Icon name="search" weight="line" class="absolute ml-2 top-1/2 mt-[-10px]" />

          <input
            type="search"
            placeholder="Search…"
            class="selection-manager-search-input w-full rounded-tl-sm grow shrink bg-transparent border-0 !ring-inset !ring-eggplant-700/50 focus-visible:ring-1 pl-8 text-lg"
            bind:value={searchQuery}
          />
        </div>

        <button
          class="uppercase border-l flex items-center px-3 grow outline-none ring-inset focus-visible:ring-1 rounded-tr-sm no-tap-highlight active:bg-neutral-100/50"
          on:click={beginCreateCollection}
        >
          <Icon name="add" weight="line" />
          <span>New</span>
        </button>
      </div>

      {#if searchQuery && filteredCollections.length === 0}
        <p class="text-sm text-center py-4 px-8 text-neutral-500">
          No collections found, please try a different search.
        </p>
      {:else}
        <ol
          class="max-h-[205px] overflow-auto rounded-b-sm"
          class:opacity-75={$selectionManagement.loadingProductSelections}
        >
          {#each filteredCollections as collection, i (collection.id)}
            <SelectionManagerCollection
              {collection}
              class="{i === 0 ? '' : 'border-t'} border-neutral-50"
              on:close={closeComponent}
            />
          {/each}
        </ol>
      {/if}
    </div>
  {/if}
</div>
