<script>
  import { createEventDispatcher } from "svelte"

  export { className as class }
  let className = ""

  const dispatch = createEventDispatcher()

  function handleKeydown(event) {
    if (event.key === "Escape") {
      dispatch("close")
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} />

<div class="bg-canvas border contrast-more:border-neutral-500 shadow-md p-4 {className}">
  <slot />
</div>
