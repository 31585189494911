<script lang="ts">
  export let name: string
  export let weight: "line" | "fill" | undefined = undefined

  let className = ""
  export { className as class }
</script>

{#if weight}
  <i class="c-icon ri-{name}-{weight} {className}" aria-hidden="true" data-testid="icon" />
{:else}
  <i class="c-icon ri-{name} {className}" aria-hidden="true" data-testid="icon" />
{/if}
