<script>
  import DialogBody from "@objects/DialogBody.svelte"
  import Dialog from "@objects/Dialog.svelte"
  import Card from "@objects/Card.svelte"
  import Button from "@components/Button.svelte"
  import { closeModal } from "svelte-modals"

  export let onConfirm
</script>

<Dialog on:close>
  <DialogBody class="[ shadow-xl md:max-w-2xl w-full ]">
    <Card class="space-y-6">
      <h2 class="text-3xl font-semibold tracking-tight">Update and Apply to All</h2>

      <p>
        This operation will save the changes you have made to this product in your other collections where this product
        appears. Are you sure you want to change this product in all of your collections?
      </p>

      <div class="space-x-4 flex flex-col md:flex-row md:justify-end">
        <Button type="outline" on:click={closeModal} class="">Cancel</Button>
        <Button type="primary" on:click={() => onConfirm()}>Yes, update and apply to all</Button>
      </div>
    </Card>
  </DialogBody>
</Dialog>
