<script>
  import clickOutside from "@actions/use-click-outside"
  import { flip, offset, shift } from "svelte-floating-ui/dom"
  import { createFloatingActions } from "svelte-floating-ui"

  export let open = false
  export let placement = "bottom-end"
  export let zClass = "z-dropdown"

  const [floatingRef, floatingContent] = createFloatingActions({
    strategy: "absolute",
    placement: placement,
    middleware: [offset(10), shift(), flip()],
    autoUpdate: {
      ancestorResize: true,
      elementResize: true,
    },
  })

  function toggle() {
    open = !open
  }

  function close() {
    open = false
  }

  function openMenu() {
    open = true
  }

  function handleKeydown(event) {
    if (open && event.key === "Escape") {
      close()
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} />

<div use:clickOutside on:click-outside={close}>
  <div class="c-dropdown" use:floatingRef data-testid="dropdown">
    <slot name="trigger" {toggle} {close} {openMenu} />
  </div>

  <nav
    class="c-dropdown__menu absolute {zClass}"
    class:c-dropdown__menu--closed={!open}
    class:hidden={!open}
    use:floatingContent
  >
    <slot name="menu" {toggle} {close} />
  </nav>
</div>
