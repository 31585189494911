<script>
  export let size = 16

  export { className as class }
  let className = ""
</script>

<svg class={className} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.6721 1C14.0373 1 14.3333 1.29663 14.3333 1.66227V7.66667H13V2.33333H2.33333V11.666L9 5L11 7V8.886L9 6.8856L4.218 11.6667H9V13H1.6612C1.29603 13 1 12.7034 1 12.3377V1.66227C1 1.29651 1.30354 1 1.6612 1H13.6721ZM5 3.66667C5.73638 3.66667 6.33333 4.26362 6.33333 5C6.33333 5.7364 5.73638 6.33333 5 6.33333C4.26362 6.33333 3.66667 5.7364 3.66667 5C3.66667 4.26362 4.26362 3.66667 5 3.66667Z"
    fill="currentColor"
  />
  <path d="M15.3333 12H13.3333V9.33331H12V12H10L12.6667 14.6666L15.3333 12Z" fill="currentColor" />
</svg>
