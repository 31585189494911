import { writable } from "svelte/store"
import axios from "axios"
import { route } from "ziggy-js"

const useCollections = () => {
  const { subscribe, update } = writable({
    collections: [],
    initialLoadComplete: false,
  })

  return {
    subscribe,

    refresh: () =>
      axios
        .get(route("merchant.api.curation.collections.index"))
        .then((response) => response.data)
        .then((data) => {
          update((oldVal) => {
            return {
              ...oldVal,
              initialLoadComplete: true,
              collections: data,
            }
          })
          return data
        }),

    localTouch: (collectionToTouch) => {
      update((oldVal) => {
        return {
          ...oldVal,
          collections: oldVal.collections.map((collection) => {
            if (collection.id === collectionToTouch.id) {
              collection.updated_at = new Date().toISOString()
            }

            return collection
          }),
        }
      })
    },

    incrementLocalProductsCount: (collectionToIncrement) => {
      update((oldVal) => {
        return {
          ...oldVal,
          collections: oldVal.collections.map((collection) => {
            if (collection.id === collectionToIncrement.id) {
              collection.products_count += 1
            }

            return collection
          }),
        }
      })
    },

    decrementLocalProductsCount: (collectionToDecrement) => {
      update((oldVal) => {
        return {
          ...oldVal,
          collections: oldVal.collections.map((collection) => {
            if (collection.id === collectionToDecrement.id) {
              collection.products_count -= 1
            }

            return collection
          }),
        }
      })
    },
  }
}

export const collectionsStore = useCollections()
