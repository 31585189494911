import { MenuItem } from "@layouts/categories/index"

export const HomeAccents: MenuItem = {
  name: "Home Accents",
  path: "pillows-decor.home-accents",
  children: [
    {
      name: "Books",
      path: "pillows-decor.home-accents.books",
    },
    {
      name: "Picture Frames",
      path: "pillows-decor.home-accents.picture-frames",
    },
    {
      name: "Candles",
      path: "pillows-decor.home-accents.candles",
    },
    {
      name: "Decorative Accents",
      path: "pillows-decor.home-accents.decorative-accents",
    },
    {
      name: "Candle Holders",
      path: "pillows-decor.home-accents.candle-holders",
    },
    {
      name: "Trays & Boxes",
      path: "pillows-decor.home-accents.trays-boxes",
    },
    {
      name: "Vases & Jars",
      path: "pillows-decor.home-accents.vases-jars",
    },
    {
      name: "Floral & Greenery",
      path: "pillows-decor.home-accents.floral-greenery",
    },
    {
      name: "Baskets & Organization",
      path: "pillows-decor.home-accents.baskets-organization",
    },
  ],
}

export const PillowsAndThrows: MenuItem = {
  name: "Pillows & Throws",
  path: "pillows-decor.pillows-throws",
  children: [
    {
      name: "Decorative Pillows",
      path: "pillows-decor.pillows-throws.decorative-pillows",
    },
    {
      name: "Throws",
      path: "pillows-decor.pillows-throws.throws",
    },
  ],
}
