import { router } from "@inertiajs/svelte"

export default class Analytics {
  static install() {
    Analytics.identify(window.analyticsData.user, window.analyticsData.account)
    // This event fires on both initial load and page change
    router.on("navigate", async () => {
      Analytics._page()
    })
  }

  static track(event, properties) {
    window.analytics.track(event, properties)
  }

  static _page() {
    window.analytics.page.apply(window.analytics, arguments)
  }

  static async identify(user, account) {
    if (user.id) {
      // https://segment.com/docs/connections/spec/identify/
      await window.analytics.identify(user.id, {
        name: user.fullName,
        email: user.email,
        type: user.type,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
      })

      if (account.id) {
        // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#group
        window.analytics.group(account.id, {
          name: account.name,
          type: "account",
        })
      }
    }
  }
}
