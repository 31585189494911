import { MenuItem } from "@layouts/categories/index"

export const Tabletop: MenuItem = {
  name: "Tabletop",
  path: "kitchen.tabletop",
  children: [
    {
      name: "Table & Kitchen Linens",
      path: "kitchen.tabletop.table-kitchen-linens",
    },
    {
      name: "Drinkware",
      path: "kitchen.tabletop.drinkware",
    },
    {
      name: "Serverware",
      path: "kitchen.tabletop.serverware",
    },
    {
      name: "Flatware",
      path: "kitchen.tabletop.flatware",
    },
    {
      name: "Bar Tools & Accessories",
      path: "kitchen.tabletop.bar-tools-accessories",
    },
    {
      name: "Dinnerware",
      path: "kitchen.tabletop.dinnerware",
    },
  ],
}

export const CookwareAndBakeware: MenuItem = {
  name: "Cookware & Bakeware",
  path: "kitchen.cookware-bakeware",
}

export const KitchenStorage: MenuItem = {
  name: "Kitchen Storage",
  path: "kitchen.kitchen-storage",
}

export const DecorativeKitchenAccessories: MenuItem = {
  name: "Decorative Kitchen Accessories",
  path: "kitchen.decorative-kitchen-accessories",
}
