import { getContext as SvelteGetContext, setContext as SvelteSetContext } from "svelte"

const defineContext = (key) => ({
  getContext: () => SvelteGetContext(key),

  setContext: (attributes) => SvelteSetContext(key, attributes),
})

// eslint-disable-next-line no-undef
export const ComboboxContextKey = Symbol()

const { setContext, getContext } = defineContext(ComboboxContextKey)

export { setContext, getContext }
