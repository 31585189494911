<script>
  export { className as class }
  export let type = "neutral"
  export let size = "base"

  const types = {
    neutral: "o-card--neutral [ bg-neutral-50 ]",
    canvas: "o-card--canvas [ bg-canvas ]",
    beige: "bg-beige-50",
    cream: "bg-cream-50",
  }

  const sizes = {
    none: "",
    base: "o-card--base [ px-8 py-6 ]",
    modal: "p-4 sm:p-6 md:px-10 md:py-8 lg:px-12 lg:py-10",
  }

  let className = ""
</script>

<div class="o-card {types[type]} {sizes[size]} {className}" data-testid="card">
  <slot />
</div>
