<script>
  import { route } from "ziggy-js"
  import { createEventDispatcher } from "svelte"
  import auth from "@store/auth"
  import Link from "@components/Link.svelte"
  import Icon from "@components/Icon.svelte"
  import Button from "@components/Button.svelte"
  import Combomark from "@components/logo/Combomark.svelte"
  import Container from "@objects/Container.svelte"
  import TheHeaderSearch from "@components/AppHeader/TheHeaderSearch.svelte"
  import { signOut } from "@helpers/models/user"
  import feature from "@store/feature"

  export { className as class }
  export let open = false

  $: isFirmsPlus = $auth.merchant.is_firms_plus

  let className = ""

  const routes = [
    {
      url: route("merchant.home"),
      label: "Home",
      active: () => route().current("merchant.home"),
      show: true,
    },
    {
      url: route("merchant.vendors.index"),
      label: "Vendors",
      active: () => route().current("merchant.vendors.*"),
      show: true,
    },
    {
      url: route("merchant.products.index"),
      label: "Products",
      requiresSubscription: true,
      active: () => route().current("merchant.products.*"),
      show: true,
    },
    {
      url: route("merchant.collections.index"),
      label: "Collections",
      active: () => route().current("merchant.collections.*"),
      show: true,
    },
    {
      url: route("merchant.orders.index"),
      label: "Orders",
      active: () => route().current("merchant.orders.*"),
      show: true,
    },
    {
      url: route("merchant.invoices.index"),
      label: "Invoices",
      active: () => route().current("merchant.invoices.*"),
      show: feature.activated("merchant/invoicing"),
    },
    {
      url: route("merchant.products.index", {
        _query: {
          "on-clearance": 1,
        },
      }),
      label: "On Clearance",
      requiresSubscription: true,
      active: () => route().current("merchant.products.*"),
      show: true,
    },
  ]

  const dispatch = createEventDispatcher()
  function close() {
    dispatch("close")
  }
</script>

<div
  class="fixed inset-0 w-screen z-modal overflow-auto bg-beige-50 pb-16 {className}"
  class:hidden={!open}
  title="Mobile Site-wide Navigation"
  role="dialog"
>
  <Container>
    <div>
      <div class="flex items-center justify-between mb-6 pt-9 pb-6">
        <div class="flex flex-col sm:flex-row items-end sm:items-center">
          <Link href={route("merchant.home")}>
            <Combomark class="h-8" />
            <span class="sr-only">SideDoor</span>
          </Link>

          {#if isFirmsPlus}
            <p class="text-right text-2xs sm:ml-2 sm:mb-[-9px] opacity-80 uppercase">Firms+</p>
          {/if}
        </div>

        <Button on:click={close} type="beige" class="h-10 w-10 flex items-center justify-center lg:hidden">
          <Icon name="close" weight="fill" />
          <span class="sr-only">Close Navigation</span>
        </Button>
      </div>

      <TheHeaderSearch id="the-mobile-search" on:search={close} />

      <nav class="[ mt-4 ]">
        <ul class="[ space-y-4 ]">
          {#each routes as route}
            {#if route.show}
              <li>
                <span>
                  <Link on:click={close} href={route.url}>
                    <span class:text-slate-400={route.requiresSubscription && !$auth.merchant.has_active_subscription}
                      >{route.label}</span
                    >
                  </Link>
                </span>
              </li>
            {/if}
          {/each}
        </ul>
      </nav>

      <hr class="my-8" />

      <nav>
        <ul>
          <li>
            <p>
              {#if $auth.is_impersonating}
                Impersonating as
              {:else}
                Signed in as
              {/if}
            </p>
            <p class="truncate">
              <strong>{$auth.designer.email}</strong>
            </p>
          </li>

          <li class="mt-4">
            <Link on:click={close} href={route("merchant.multi-sign-in.show")}>Switch account…</Link>
          </li>

          {#if $auth.merchant.can_be.managed}
            <li class="mt-4">
              <Link on:click={close} href={route("merchant.account.overview")}>Account Settings</Link>
            </li>
          {/if}

          <li class="mt-4">
            <Link on:click={close} href={route("merchant.user-settings")}>User settings</Link>
          </li>

          {#if $auth.designer.has_superpowers}
            <li class="mt-4">
              <a href="/admin/home" data-turbolinks="false"> Admin Home </a>
            </li>
          {/if}

          <li class="mt-4">
            {#if $auth.is_impersonating}
              <a href={route("impersonate.leave")}>Leave Impersonation</a>
            {:else}
              <form on:submit|preventDefault={signOut}>
                <button type="submit">Sign Out</button>
              </form>
            {/if}
          </li>
        </ul>
      </nav>
    </div>
  </Container>
</div>
