<script>
  import Button from "@components/Button.svelte"
  import Spinner from "@components/Spinner.svelte"

  export let processing = false
  export let disabled = false
  export { className as class }

  export let processingLabel = "Processing…"
  export let isProcessingLabelScreenReaderOnly = true

  let className = ""
</script>

<Button {...$$restProps} on:click disabled={disabled || processing} submit class="c-submit-button {className}">
  {#if processing}
    <Spinner />
    <span class:sr-only={isProcessingLabelScreenReaderOnly} class="pl-2">{processingLabel}</span>
  {:else}
    <slot />
  {/if}
</Button>
