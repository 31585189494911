<script>
  import { closeAllModals, closeModal } from "svelte-modals"

  import Icon from "@components/Icon.svelte"
  import widont from "@actions/use-widont"

  export let title
  export let subtitle

  export { className as class }
  let className = ""
</script>

<header class="flex justify-between items-center {className}">
  <div>
    <button on:click={closeModal} class="nav-trigger">
      <span class="sr-only">Go to previous modal</span>
      <Icon name="arrow-left" weight="line" class="text-lg" />
    </button>
  </div>

  <div class="flex flex-col items-center gap-1 px-2 lg:px-6 xl:px-10">
    {#if title}
      <h3 class="text-2xl md:text-3xl text-center line-clamp-2">{title}</h3>
    {/if}

    {#if subtitle}
      <h4 class="text-sm sm:text-base md:text-lg text-center font-body text-neutral-600 max-w-[400px]" use:widont>
        {subtitle}
      </h4>
    {/if}

    <slot />

  </div>

  <div>
    <button on:click={closeAllModals} class="nav-trigger">
      <span class="sr-only">Close all modals</span>
      <Icon name="close" weight="line" class="text-lg" />
    </button>
  </div>
</header>

<style lang="postcss">
  .nav-trigger {
    @apply w-[40px] h-[40px] bg-beige-100 rounded-sm;
  }
</style>
