<script context="module">
  export const categoryItemEventHandlerContextKey = Symbol()
</script>

<script>
  import { route } from "ziggy-js"
  import { setContext } from "svelte"
  import Link from "@components/Link.svelte"
  import CategoryDropdown from "@layouts/categories/CategoryDropdown.svelte"

  export let category

  let className
  export { className as class }

  let isVisible = false
  let rootEl = null

  const Placements = {
    left: "[ left-0 ]",
    right: "[ left-0 lg:left-auto lg:right-0 ]",
  }
  const show = () => {
    isVisible = true
  }
  const hide = () => {
    isVisible = false
  }

  const handlePointerEnter = (e) => {
    // Only handle the pointer enter event if it's actually a mouse. Touch
    // events will be handled by the focus/click events.
    if (e.pointerType !== "mouse") {
      return
    }
    show()
  }

  const handleFocus = () => {
    // "Delay" required to prevent showing before the click event handler fires
    // on Android. Note that Svelte's `tick` doesn't work here.
    setTimeout(show, 0)
  }

  const handleBlur = (e) => {
    // Stay open if blurred to a child of this category
    if (!rootEl.contains(e.relatedTarget)) {
      hide()
    }
  }

  const handleClick = (e) => {
    if (isVisible) {
      hide()
    } else {
      e.preventDefault()
      show()
    }
  }

  // Set context for children of this component (CategoryDropdown, CategoryList)
  setContext(categoryItemEventHandlerContextKey, {
    handleBlur,
    handleClick: hide,
  })
</script>

<li class="whitespace-nowrap group xl:relative {className}" on:mouseleave={hide} bind:this={rootEl}>
  <div>
    {#if category.path}
      <Link
        href={route("merchant.products.index", { categories: [category.path] })}
        on:click={handleClick}
        on:pointerenter={handlePointerEnter}
        on:focus={handleFocus}
        on:blur={handleBlur}
        class="peer block text-center px-4 py-3 border-b-2 {isVisible ? 'border-accent-base' : 'border-transparent'}"
      >
        {category.name}
      </Link>
    {:else if category.routeParams}
      <Link
        href={route("merchant.products.index", category.routeParams)}
        on:click={handleClick}
        on:pointerenter={handlePointerEnter}
        on:focus={handleFocus}
        on:blur={handleBlur}
        class="peer block text-center px-4 py-3 border-b-2 {isVisible ? 'border-accent-base' : 'border-transparent'}"
      >
        {category.name}
      </Link>
    {:else}
      <span
        on:pointerenter={handlePointerEnter}
        on:focus={handleFocus}
        on:blur={handleBlur}
        class="peer block text-center px-4 py-3 border-b-2 {isVisible ? 'border-accent-base' : 'border-transparent'}"
      >
        {category.name}
      </span>
    {/if}
  </div>

  <div
    class="
      w-full xl:w-auto absolute top-[42px] z-navigation
      {Placements[category.placement || 'left']}
    "
    class:hidden={!isVisible}
    class:block={isVisible}
  >
    <CategoryDropdown {category} />
  </div>
</li>
