<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import { router, useForm } from "@inertiajs/svelte"
  import SubmitButton from "@components/SubmitButton.svelte"
  import notifications from "@store/notifications"
  import Button from "@components/Button.svelte"
  import FormField from "@components/FormField.svelte"
  import Dialog from "@objects/Dialog.svelte"
  import DialogBody from "@objects/DialogBody.svelte"
  import Card from "@objects/Card.svelte"
  import { toHuman } from "@helpers/formatters/currency"
  import InvalidProductsDialog from "@components/dialogs/CouponDialog/InvalidProductsDialog.svelte"
  import { closeAllModals, closeModal, openModal } from "svelte-modals"
  import Spinner from "@components/Spinner.svelte"
  import DialogActions from "@components/dialogs/CouponDialog/DialogActions.svelte"
  import Analytics from "@services/Analytics"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"

  export let isOpen
  export let onClose
  export let collection

  let processing
  let activeCoupon
  let subtotals
  let maximumDiscountPercentage

  let form = useForm({
    code: activeCoupon?.code,
    percent_off: activeCoupon?.percent_off,
  })

  $: discountedAmount = calculateDiscountedAmount(subtotals?.retail, $form.percent_off)

  function calculateDiscountedAmount(retailAmount, discountAmount) {
    if (!retailAmount || !discountAmount) {
      return 0
    }

    return Math.floor(retailAmount * (discountAmount / 100) * 100) / 100
  }

  function createCoupon() {
    $form.post(route("merchant.collections.coupons.store", collection), {
      onSuccess: (page) => {
        const coupon = page.props.collection.activeCoupon

        notifications.add({ type: "success", message: "Coupon created successfully." })
        trackCouponAnalyticsEvent(coupon, "updated")
        localStorage.setItem("sd/designer/sidebar/collection", collection.id)
        closeModal()
      },
      onError: () => {
        if ($form.errors?.invalid_products?.length > 0) {
          openInvalidProductsDialog()
        }
      },
    })
  }

  function updateCoupon() {
    $form.put(route("merchant.collections.coupons.update", [collection, activeCoupon]), {
      onSuccess: (page) => {
        const coupon = page.props.collection.activeCoupon

        notifications.add({ type: "success", message: "Coupon updated successfully." })
        trackCouponAnalyticsEvent(coupon, "updated")
        localStorage.setItem("sd/designer/sidebar/collection", collection.id)
        closeModal()
      },
      onError: () => {
        if ($form.errors?.invalid_products?.length > 0) {
          openInvalidProductsDialog()
        }
      },
    })
  }

  function deleteCoupon() {
    router.delete(route("merchant.collections.coupons.destroy", [collection, activeCoupon]), {
      onBefore: () => confirm("Are you sure you want to delete this coupon?"),
      onSuccess: () => {
        notifications.add({ type: "success", message: "Coupon deleted successfully." })
        trackCouponAnalyticsEvent(activeCoupon, "deleted")

        localStorage.setItem("sd/designer/sidebar/collection", collection.id)
        closeModal()
        $form.reset()
      },
    })
  }

  function submit() {
    if (activeCoupon) {
      updateCoupon()
    } else {
      createCoupon()
    }
  }

  function trackCouponAnalyticsEvent(coupon, eventName) {
    Analytics.track(`Merchant ${eventName} coupon`, {
      collectionId: collection.id,
      couponId: coupon.id,
      percentOff: coupon.percent_off,
    })
  }

  function openInvalidProductsDialog() {
    openModal(InvalidProductsDialog, {
      coupon: activeCoupon,
      collection,
      products: $form.errors.invalid_products,
      attemptedPercentOff: $form.percent_off,
      onClose: () => {
        $form.clearErrors()

        if (onClose && typeof onClose === "function") {
          onClose()
        }

        fetchCollection()
      },
    })
  }

  async function fetchCollection(setFormData = false) {
    processing = true
    const { data } = await axios.get(route("merchant.collections.show", collection))
    processing = false

    activeCoupon = data.collection.activeCoupon
    subtotals = data.subtotals
    maximumDiscountPercentage = data.maximumDiscountPercentage

    if (activeCoupon && setFormData) {
      $form.code = activeCoupon.code
      $form.percent_off = activeCoupon.percent_off
    }
  }

  fetchCollection(true)
</script>

{#if isOpen}
  <Dialog on:close={closeAllModals}>
    <DialogBody class="shadow-xl md:max-w-2xl w-full">
      <Card size="modal" type="canvas" class="space-y-8">
        <DialogHeader title="Manage Coupon" subtitle={collection.name} />

        {#if processing}
          <div class="flex items-center justify-center space-x-2">
            <Spinner />
            <span class="sr-only">Loading Coupon…</span>
          </div>
        {:else}
          <form on:submit|preventDefault={submit} class="space-y-4">
            <FormField label="Coupon Code" name="code" error={$form.errors.code}>
              <input
                class="w-full font-mono"
                type="text"
                id="code"
                name="code"
                placeholder="specialoffer"
                bind:value={$form.code}
                required
              />
            </FormField>

            <FormField name="percent_off" error={$form.errors.percent_off}>
              <span slot="label">
                Discount Percentage <span class="text-orange-500">({maximumDiscountPercentage}% Max)</span>
              </span>

              <input
                class="w-full"
                type="number"
                id="percent_off"
                name="percent_off"
                placeholder="10"
                min="0.01"
                step="0.01"
                bind:value={$form.percent_off}
                required
              />
            </FormField>

            <p class="text-base text-right">
              Potential discount amount: <span class="">{toHuman(discountedAmount)}</span>
            </p>

            <DialogActions>
              {#if activeCoupon}
                <Button on:click={deleteCoupon} disabled={$form.processing} type="destructive">Delete</Button>
              {/if}

              <SubmitButton disabled={$form.processing}>Save</SubmitButton>
            </DialogActions>
          </form>
        {/if}
      </Card>
    </DialogBody>
  </Dialog>
{/if}
