import { MenuItem } from "@layouts/categories/index"

export const AllRugsAndPads: MenuItem = {
  name: "All Rugs & Pads",
  path: "rugs.all-rugs-pads",
  children: [
    {
      name: "Antique",
      path: "rugs.all-rugs-pads.antique",
    },
    {
      name: "Hand Tufted",
      path: "rugs.all-rugs-pads.hand-tufted",
    },
    {
      name: "Machine Made",
      path: "rugs.all-rugs-pads.machine-made",
    },
    {
      name: "Hand Knotted",
      path: "rugs.all-rugs-pads.hand-knotted",
    },
    {
      name: "Outdoor/Performance",
      path: "rugs.all-rugs-pads.outdoorperformance",
    },
    {
      name: "Natural",
      path: "rugs.all-rugs-pads.natural",
    },
  ],
}
