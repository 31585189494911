<script>
  import axios from "axios"

  export let items

  $: isLoading = false
  $: onLastPage = items.links.next === null

  async function next() {
    if (onLastPage) {
      return
    }

    isLoading = true

    append(await axios.get(items.links.next).then((response) => response.data))

    isLoading = false
  }

  function append({ data, links }) {
    items.data = [...items.data, ...data]
    items.links = links
  }
</script>

<slot {isLoading} {onLastPage} {next} />
