import { MenuItem } from "@layouts/categories/index"

export const WallcoveringPattern: MenuItem = {
  name: "Pattern",
  path: "wallcovering",
  children: [
    {
      name: "Abstract",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["abstract"],
      },
    },
    {
      name: "Animal",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["animal"],
      },
    },
    {
      name: "Botanical & floral",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["botanical & floral"],
      },
    },
    {
      name: "Chinoiserie",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["chinoiserie"],
      },
    },
    {
      name: "Damask",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["damask"],
      },
    },
    {
      name: "Geometric",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["geometric"],
      },
    },
    {
      name: "Plaid/check",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["plaid", "check"],
      },
    },
    {
      name: "Solid",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["solid"],
      },
    },
    {
      name: "Stripes",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["stripes"],
      },
    },
  ],
}

export const WallcoveringMaterial: MenuItem = {
  name: "Material",
  path: "wallcovering",
  children: [
    {
      name: "Borders",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["borders"],
      },
    },
    {
      name: "Embroidery",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["embroidery"],
      },
    },
    {
      name: "Grasscloth",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["grasscloth"],
      },
    },
    {
      name: "Murals/Panels",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["murals", "panels"],
      },
    },
    {
      name: "Print",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["print"],
      },
    },
    {
      name: "Vinyl/Faux Leather",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["vinyl", "faux-leather"],
      },
    },
    {
      name: "Weave",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["Weave"],
      },
    },
    {
      name: "Wood",
      routeParams: {
        categories: ["wallcovering"],
        patterns: ["wood"],
      },
    },
  ],
}
