import { get, writable } from "svelte/store"
import { tick } from "svelte"

function defineChange({ update }) {
  return (data) =>
    update(($store) => {
      $store = { ...$store, ...data }

      return $store
    })
}

function buildActions($actions, store) {
  function applyContextToCallback(callback, ...rest) {
    return callback.bind({
      $change: defineChange(store),
      ...actions,
    })(get(store), ...rest)
  }

  const actions = Object.fromEntries(
    Object.entries($actions).map(function ([key, callback]) {
      return [key, (...rest) => applyContextToCallback(callback, ...rest)]
    }),
  )

  return actions
}

export function defineStore(data, onStart = () => {}) {
  const { state: $state, actions: $actions } = data

  const store = writable($state)

  onStart(store)

  return {
    ...store,
    ...buildActions($actions, store),
  }
}

export const useCombobox = (config) =>
  defineStore({
    state: {
      open: false,
      options: [],

      $group: null,
      $root: null,
      $input: null,
      $options: null,

      ...config,
    },

    actions: {
      select($state, value) {
        if (value && value.callback) {
          value.callback(this)
        } else if ($state.multiple) {
          this._selectMultiple(value)
        } else {
          this._selectOne(value)
        }

        $state.$root.dispatchEvent(new CustomEvent("input", { detail: { value } }))
      },

      selected($state, value) {
        if ($state.multiple) {
          return $state.value.includes(value)
        } else {
          return $state.value === value
        }
      },

      focus({ $input }) {
        $input.focus({ preventScroll: true })
      },

      clear({ $input }) {
        if (!$input) {
          return
        }

        $input.value = ""
      },

      async open({ open, $root, $options }) {
        if (open) {
          return
        }

        this.$change({ open: true })

        await tick()

        $root && $root.dispatchEvent(new CustomEvent("open"))
        $options && $options.dispatchEvent(new CustomEvent("open"))
      },

      async close({ open, $root, $options }) {
        if (!open) {
          return
        }

        this.$change({ open: false })

        await tick()

        $root && $root.dispatchEvent(new CustomEvent("close"))
        $options && $options.dispatchEvent(new CustomEvent("close"))
      },

      toggle({ open }) {
        if (open) {
          this.close()
        } else {
          this.open()
        }
      },

      // $internal

      _selectOne($state, value) {
        this.$change({ value: value })

        this.close()
      },

      _addValue($state, value) {
        this.$change({ value: [...$state.value, value] })
      },

      _removeValue($state, value) {
        this.$change({
          value: $state.value.filter(($value) => $value === value),
        })
      },

      _selectMultiple($state, value) {
        if ($state.value.includes(value)) {
          this._removeValue(value)
        } else {
          this._addValue(value)
        }

        this.close()
      },
    },
  })
