<script>
  import Dropdown from "@components/Dropdown.svelte"

  export let type = "neutral"
  export let size = "base"
  export { className as class }

  let className = ""

  const types = {
    neutral: "c-pill--neutral bg-neutral-100 text-neutral-800 rounded-full",
    profit: "c-pill--profit bg-green-100 text-green-900 rounded-full",
    destructive: "c-pill--destructive bg-red-100 text-red-800 rounded-full",
  }

  const tooltipIndicatorTypes = {
    neutral: "bg-neutral-50 shadow",
    profit: "",
    destructive: "",
  }

  const sizes = {
    none: "",
    tiny: "c-pill--tiny px-2 py-0.5 text-xs",
    small: "c-pill--small px-3 py-1 text-sm",
    base: "c-pill--base px-3 py-1.5",
  }
</script>

{#if $$slots.tooltipContent}
  <Dropdown placement="top-middle">
    <svelte:fragment slot="trigger" let:toggle let:close let:openMenu>
      <div class="relative flex items-center">
        <span
          role="button"
          class="c-pill cursor-help {sizes[size]} {types[type]} {className}"
          data-testid="pill"
          on:mouseenter={openMenu}
          on:mouseleave={close}
        >
          <slot />
          <span class="pl-1" />
        </span>
        <span
          class="absolute top-0 right-0 -mt-1.5 -mr-1.5 not-sr-only text-xs font-semibold rounded-full w-4 h-4 text-center pointer-events-none leading-[14px] pointer-events-none {tooltipIndicatorTypes[
            type
          ]}">?</span
        >
      </div>
    </svelte:fragment>

    <svelte:fragment slot="menu">
      <div class="w-[200px] bg-white text-xs p-2 shadow">
        <slot name="tooltipContent" />
      </div>
    </svelte:fragment>
  </Dropdown>
{:else}
  <span class="c-pill {sizes[size]} {types[type]} {className}" data-testid="pill">
    <slot />
  </span>
{/if}
