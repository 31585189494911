<script>
  import { page } from "@inertiajs/svelte"
  import Dropdown from "@components/Dropdown.svelte"
  import DropdownMenu from "@components/DropdownMenu.svelte"
  import TheUserNavigation from "@components/AppHeader/TheUserNavigation.svelte"
</script>

<Dropdown placement="bottom-end" zClass="z-modal">
  <svelte:fragment slot="trigger" let:toggle>
    <button type="button" on:click|preventDefault={toggle}>
      <span
        class="flex items-center justify-center shrink-0 bg-accent-base tracking-tighter text-white font-bold h-9 w-9 text-xs"
      >
        {$page.props.auth.designer.name.initials}
      </span>

      <span class="sr-only">{$page.props.auth.designer.full_name}</span>
    </button>
  </svelte:fragment>

  <svelte:fragment slot="menu" let:close>
    <DropdownMenu class="[ w-[18rem] ]" on:close={close}>
      <TheUserNavigation on:close={close} />
    </DropdownMenu>
  </svelte:fragment>
</Dropdown>
