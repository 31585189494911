<script>
  import { closeAllModals } from "svelte-modals"
  import Dialog from "@objects/Dialog.svelte"
  import DialogBody from "@objects/DialogBody.svelte"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"
  import Card from "@objects/Card.svelte"
  import ShareCollectionDialogBeforeYouShareSteps from "./ShareCollectionDialogBeforeYouShareSteps.svelte"
  import ShareCollectionDialogDestinations from "./ShareCollectionDialogDestinations.svelte"

  export let collection
  export let isOpen
</script>

{#if isOpen}
  <Dialog on:close={closeAllModals}>
    <DialogBody class="md:max-w-7xl w-full">
      <Card size="modal" type="canvas" class="flex flex-col md:relative">
        <DialogHeader title="Share your collection" subtitle={collection.name} />

        <div class="grid grid-cols-1">
          <div class="mt-8 order-last md:order-first">
            <ShareCollectionDialogBeforeYouShareSteps {collection} />
          </div>

          <div class="mt-16">
            <ShareCollectionDialogDestinations {collection} />
          </div>
        </div>
      </Card>
    </DialogBody>
  </Dialog>
{/if}
