import { MenuItem } from "@layouts/categories/index"

export const CeilingLightsAndFans: MenuItem = {
  name: "Ceiling Lights & Fans",
  path: "lighting.ceiling-lights-fans",
  children: [
    {
      name: "Flush Mounts",
      path: "lighting.ceiling-lights-fans.flush-mounts",
    },
    {
      name: "Chandeliers",
      path: "lighting.ceiling-lights-fans.chandeliers",
    },
    {
      name: "Pendants",
      path: "lighting.ceiling-lights-fans.pendants",
    },
  ],
}

export const TableAndFloorLamps: MenuItem = {
  name: "Table & Floor Lamps",
  path: "lighting.table-floor-lamps",
  children: [
    {
      name: "Table Lamps",
      path: "lighting.table-floor-lamps.table-lamps",
    },
    {
      name: "Floor Lamps",
      path: "lighting.table-floor-lamps.floor-lamps",
    },
  ],
}

export const WallLighting: MenuItem = {
  name: "Wall Lighting",
  path: "lighting.wall-lighting",
  children: [
    {
      name: "Sconces",
      path: "lighting.wall-lighting.sconces",
    },
  ],
}
