<script lang="ts">
  export let label: string | undefined = undefined
  export let name: string
  export let error = ""
  export { className as class }

  let className = ""
</script>

<div class="c-form-field {className}" data-testid="form-field">
  {#if label || $$slots.label || $$slots.hint}
    <div class="[ mb-2 ]">
      {#if label || $$slots.label}
        <label
          for={name}
          class="c-form-field__label [ block font-semibold tracking-tight leading-tight ]"
          class:c-form-field__label--error={error}
          class:text-red-600={error}
        >
          {#if $$slots.label}
            <slot name="label" />
          {:else}
            {label}
          {/if}
        </label>
      {/if}

      {#if $$slots.hint}
        <div class="[ mt-0.5 mb-3 text-neutral-800 ]">
          <slot name="hint" />
        </div>
      {/if}
    </div>
  {/if}

  <div class="c-form-field__control">
    <slot />
  </div>

  {#if error}
    <p class="c-form-field__message c-form-field__message--error [ text-red-600 font-semibold mt-1 leading-tight ]">
      {error}
    </p>
  {/if}
</div>
