<script>
  import Dropdown from "@components/Dropdown.svelte"

  export let title
  export let value

  export let obscureValue = false

  export let valueClass = ""
</script>

<div class="flex justify-between">
  <dt class="font-heading flex items-center gap-2">
    {#if $$slots.tooltipContent}
      <Dropdown placement="top-start">
        <svelte:fragment slot="trigger" let:toggle let:close let:openMenu>
          <div class="flex items-center gap-1.5 cursor-help font-body" on:mouseenter={openMenu} on:mouseleave={close}>
            <div>
              <span class="align-[-1px]">{title}</span>
            </div>

            <div>
              <span
                class="not-sr-only text-xs font-semibold rounded-full w-4 h-4 text-center bg-neutral-50 shadow font-body block -mt-1"
                >?</span
              >
            </div>
          </div>
        </svelte:fragment>

        <svelte:fragment slot="menu">
          <div class="w-[200px] bg-white text-xs p-2 shadow">
            <slot name="tooltipContent" />
          </div>
        </svelte:fragment>
      </Dropdown>
    {:else}
      <div>
        <span class="align-[-1px] font-body">{title}</span>
      </div>
    {/if}
  </dt>

  <dd class:blur={obscureValue} class:select-none={obscureValue}>
    <span class={valueClass}>{value}</span>
  </dd>
</div>
