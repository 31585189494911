<script>
  import { closeAllModals, closeModal } from "svelte-modals"
  import { onMount } from "svelte"
  import axios from "axios"

  import Analytics from "@services/Analytics"
  import ProductPage from "@components/ProductPage.svelte"
  import Dialog from "@objects/Dialog.svelte"
  import Card from "@objects/Card.svelte"
  import Container from "@objects/Container.svelte"
  import Spinner from "@components/Spinner.svelte"
  import notifications from "@store/notifications"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"

  export let isOpen
  export let productUrl

  let initialLoadComplete = false
  let product = null

  const init = async () => {
    try {
      const res = await axios.get(productUrl)
      product = res.data

      Analytics.track("Merchant viewed product preview", {
        id: product.id,
        name: product.name,
        url: productUrl,
      })

      initialLoadComplete = true
    } catch (err) {
      closeModal()

      notifications.error({
        title: "Error previewing product",
        message: err,
      })
    }
  }

  onMount(() => {
    init()
  })
</script>

{#if isOpen}
  <Dialog on:close={closeAllModals}>
    <Container class="relative grow" padding="none" size="huge">
      <Card type="canvas" class="shadow-xl text-left" size="modal">
        <DialogHeader class="mb-6" />

        {#if initialLoadComplete}
          <ProductPage {product} />
        {:else}
          <div class="flex items-center justify-center space-x-2">
            <Spinner />
            <span class="sr-only">Loading product…</span>
          </div>
        {/if}
      </Card>
    </Container>
  </Dialog>
{/if}
