<script>
  import Container from "@objects/Container.svelte"
  import TheCategoryItem from "@components/AppHeader/TheCategoryItem.svelte"
  import categories from "@layouts/categories"
  import InactiveSubscriptionInteractivityBlocker from "@components/InactiveSubscriptionInteractivityBlocker.svelte"
  import FeaturedVendors from "@components/AppHeader/FeaturedVendors.svelte"
  import auth from "@store/auth"
  import Button from "@components/Button.svelte"
  import feature from "@store/feature.js"

  export { className as class }
  let className = ""
</script>

<!-- Force z-index to be above horizontal search dropdowns -->
<div class="sticky top-0 z-[51]">
  <nav
    class="relative w-full border-b border-beige-200 text-neutral-600 bg-beige-100 {className}"
    aria-label="Category List"
  >
    <Container class="overflow-hidden xl:overflow-visible">
      <ul
        class="grid grid-flow-col -ml-[8vw] px-[8vw] w-screen overflow-x-auto xl:ml-0 xl:px-0 lg:w-auto xl:overflow-x-visible"
      >
        <li class="vendor-link group lg:relative whitespace-nowrap">
          <FeaturedVendors />
        </li>
        {#each categories as category}
          <TheCategoryItem {category} />
        {/each}
      </ul>
    </Container>

    <InactiveSubscriptionInteractivityBlocker />
  </nav>

  {#if !$auth.merchant.has_active_subscription}
    <div class="text-center py-4 px-6 bg-red-500 font-bold text-white">
      <p>
        Your account subscription must be reactivated to use SideDoor Firms+. Please <Button
          on:click={() => window.FrontChat("show")}
          type="none"
          size="none"
          excludeBaseStyle
          class="underline">contact support</Button
        > for assistance.
      </p>
    </div>
  {/if}
</div>

<style>
  .vendor-link:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    @apply bg-beige-200;
  }
</style>
