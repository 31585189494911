<script>
  import Image from "@components/Image.svelte"

  export let images
  export let selected
  export { className as class }

  let className = ""

  function selectImage(image) {
    selected = image
  }
</script>

<div class="{className} thumbnail-gallery grid gap-2 grid-cols-5 md:grid-cols-6 lg:grid-cols-8">
  {#each images as image, i (image.id)}
    <button on:click={() => selectImage(image)} class="[ w-full relative ]">
      <span class="[ sr-only ]">View image {i + 1}</span>

      <Image src={image.url} alt="Image {i + 1} of {images.length}" width="100" aspect="square" />

      {#if selected.id == image.id}
        <span class="absolute inset-0 block bg-stroke bg-opacity-50" />
      {/if}
    </button>
  {/each}
</div>
