const Transformations = {
  height: "h",
  h: "h",
  width: "w",
  w: "w",
  quality: "q",
  q: "q",
  aspectRatio: "ar",
  ar: "ar",
  cropMode: "cm",
  cm: "cm",
}

function createImageKitTransformation(parameters = {}) {
  return Object.keys(parameters)
    .filter((key) => parameters[key] !== undefined)
    .map((key) => `${Transformations[key]}-${parameters[key]}`)
    .join(",")
}

export default function asset(url, parameters = {}) {
  return `${url}?tr=${createImageKitTransformation(parameters)}`
}
