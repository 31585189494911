import * as SentrySvelte from "@sentry/svelte"
import { BrowserTracing } from "@sentry/tracing"

export default class Sentry {
  static install() {
    if (!window.sentrySettings) {
      return
    }

    SentrySvelte.init({
      dsn: window.sentrySettings.dsn,
      environment: window.sentrySettings.environment,
      release: __VITE_COMMIT_HASH__,
      integrations: [new BrowserTracing(), new SentrySvelte.Replay()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: window.sentrySettings.tracesSampleRate,
      replaysSessionSampleRate: window.sentrySettings.replaysSessionSampleRate,
      replaysOnErrorSampleRate: window.sentrySettings.replaysOnErrorSampleRate,
      ignoreErrors: [
        // https://github.com/getsentry/sentry-javascript/issues/3440
        /^UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching/,
      ],
    })
  }
}
