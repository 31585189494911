import { MenuItem } from "@layouts/categories/index"

export const WallArt: MenuItem = {
  name: "Wall Art",
  path: "wall-art-mirrors.wall-art",
}

export const WallAccents: MenuItem = {
  name: "Wall Accents",
  path: "wall-art-mirrors.wall-accents",
}

export const Photography: MenuItem = {
  name: "Photography",
  path: "wall-art-mirrors.photography",
}

export const WallMirrors: MenuItem = {
  name: "Wall Mirrors",
  path: "wall-art-mirrors.wall-mirrors",
}

export const FloorMirrors: MenuItem = {
  name: "Floor Mirrors",
  path: "wall-art-mirrors.floor-mirrors",
}
