<script>
  import Icon from "@components/Icon.svelte"

  export let id
  export let name
  export let value
  export let error = ""
  export let size = "base"

  let className = ""
  export { className as class }

  export let wrapperClass = ""

  const Sizes = {
    none: "",
    tiny: "c-select px-3 py-1",
    small: "c-select [ px-4 py-2 ]",
    base: "c-select [ px-5 py-3 ]",
  }
</script>

<div class="relative group {wrapperClass}">
  <select
    {id}
    {name}
    bind:value
    on:input
    on:focus
    on:blur
    on:change
    class:border-neutral-100={!error}
    class:contrast-more:border-neutral-500={!error}
    class:border-red-500={error}
    class="c-select block w-full border text-stroke bg-canvas bg-none cursor-pointer {Sizes[size]} {className}"
  >
    <slot />
  </select>

  <Icon
    name="arrow-down-s"
    weight="line"
    class="absolute right-3 top-1/2 -translate-y-1/2 text-neutral-400 pointer-events-none group-hover:text-neutral-700"
  />
</div>
