<script>
  import NotificationDrawer from "@components/NotificationDrawer.svelte"
  import IntersectionObserver from "@objects/IntersectionObserver.svelte"
  import AppHeader from "@components/AppHeader/AppHeader.svelte"
  import TheCategoryBar from "@components/AppHeader/TheCategoryBar.svelte"
  import { router } from "@inertiajs/svelte"
  import Icon from "@components/Icon.svelte"
  import SelectionManager from "@components/SelectionManager.svelte"
  import { slide } from "svelte/transition"
  import { Modals } from "svelte-modals"

  router.on("navigate", () => {
    document.activeElement.blur()
  })

  function backToTop() {
    window.scrollTo(0, 0)
  }

  let showBackToTop = false
</script>

<div class="l-app">
  <a
    href="#content"
    type="neutral"
    class="
      [ absolute top-3 left-4 opacity-0 bg-neutral-100 px-4 py-2 z-navigation ]
      [ focus:opacity-100 pointer-events-none focus:pointer-events-auto ]
    "
  >
    Skip to main content
  </a>

  <AppHeader class="l-app__header" />

  <TheCategoryBar class="l-app__category-bar" />

  <main id="content" class="l-app__main">
    <slot />
  </main>

  <Modals />

  <SelectionManager />

  <NotificationDrawer />

  <IntersectionObserver
    on:intersect={() => (showBackToTop = false)}
    on:release={() => (showBackToTop = true)}
    class="absolute top-0 left-0 h-screen w-[1x] z-navigation"
  >
    {#if showBackToTop}
      <div class="fixed bottom-0 pb-28 lg:pb-8 left-8 pointer-events-none" transition:slide|local={{ duration: 200 }}>
        <button
          type="button"
          on:click={backToTop}
          class="bg-canvas border shadow-lg focus:ring-2 ring-offset-2 ring-eggplant-800 px-3 py-1 font-semibold pointer-events-auto"
        >
          <span class="relative bottom-px text-sm">
            <Icon name="arrow-up-line" class="font-normal relative top-[2.5px] mr-1" />
            Back to top
          </span>
        </button>
      </div>
    {/if}
  </IntersectionObserver>
</div>
