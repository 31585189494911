<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte"

  export let title
  export { className as class }

  const dispatch = createEventDispatcher()

  let element
  let className = ""

  function close() {
    dispatch("close")
  }

  function closeOnEscape(event) {
    if (event.key === "Escape") {
      close()
    }
  }

  onMount(() => {
    document.body.classList.add("overflow-hidden")
    dispatch("open")

    element.dispatchEvent(new CustomEvent("mount"))
  })

  onDestroy(() => {
    document.body.classList.remove("overflow-hidden")
  })
</script>

<svelte:window on:keydown={closeOnEscape} />

<div
  class="inset-0 absolute relative z-modal {className}"
  {title}
  on:mount
  role="dialog"
  aria-modal="true"
  tabindex="0"
  bind:this={element}
>
  <div class="fixed inset-0 bg-neutral-700/75 transition-opacity" />

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end md:items-center justify-center min-h-full p-4 lg:py-16 text-center"
      on:click|self={close}
    >
      <slot />
    </div>
  </div>
</div>
