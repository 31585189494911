<script>
  import { openModal, closeModal } from "svelte-modals"
  import { route } from "ziggy-js"
  import Icon from "@components/Icon.svelte"
  import Button from "@components/Button.svelte"
  import CouponDialog from "@components/dialogs/CouponDialog/CouponDialog.svelte"
  import feature from "@store/feature"

  export let collection

  let shouldIncludeCouponCard = !feature.activated("firms-plus/disable-customer-coupon-use")

  const preCheckoutSteps = [
    {
      name: "Edit products",
      icon: "pencil-line",
      description:
        "Don’t forget to edit your products. Changing names and descriptions can help protect your designs from being shopped.",
      supportText: "Learn more about customizing your products.",
      supportUrl: "https://support.onsidedoor.com/en/articles/1971201",
      action: {
        text: "Back to editing",
        onClick: () => {
          closeModal()
        },
      },
    },
    ...(shouldIncludeCouponCard
      ? [
          {
            name: "Create a coupon",
            icon: "money-dollar-box-line",
            description: "You can create a coupon for your client to help meet your estimate and win the sale.",
            supportText: "Learn about offering coupon discounts to your customers.",
            supportUrl: "https://support.onsidedoor.com/en/articles/2014401",
            action: {
              text: "Create a coupon",
              onClick: () => {
                openModal(CouponDialog, {
                  collection,
                })
              },
            },
          },
        ]
      : []),
    {
      name: "Preview & customize",
      icon: "gallery-line",
      description: "You can customize your account theme so that your shared collections show with your own branding.",
      supportText: "View our customization guide to learn more.",
      supportUrl: "https://support.onsidedoor.com/en/articles/1971265",
      action: {
        text: "Customize theme",
        onClick: () => {
          open(route("merchant.account.edit"))
        },
      },
    },
  ]
</script>

<p class="text-xl">Before you share…</p>

<div
  class="mt-4 grid grid-cols-1 gap-6"
  class:md:grid-cols-2={!shouldIncludeCouponCard}
  class:md:grid-cols-3={shouldIncludeCouponCard}
>
  {#each preCheckoutSteps as step}
    <div class="flex flex-col">
      <div class="bg-beige-50 grow flex flex-col pt-8 px-8 pb-6">
        <div class="grow">
          <h3 class="text-xl font-bold flex items-center relative">
            <Icon name={step.icon} class="font-normal -mt-1.5 mr-2" />
            <span>{step.name}</span>
          </h3>

          <p class="mt-4 mb-4 opacity-80">
            {step.description}&nbsp;<a
              class="underline inline"
              target="_blank"
              rel="no-opener no-referrer"
              href={step.supportUrl}>{step.supportText}</a
            >
          </p>
        </div>
      </div>

      <Button
        type="beige"
        class="flex items-center space-x-2 py-4 border-0 border-t border-beige-300"
        on:click={() => step.action.onClick()}
      >
        <span>{step.action.text}</span>
        <Icon name="arrow-right-line" />
      </Button>
    </div>
  {/each}
</div>
