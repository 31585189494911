<script>
  export let size = 16

  export { className as class }
  let className = ""
</script>

<svg class={className} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_8)">
    <path d="M0.75 0.75H6.75V6.75H0.75V0.75Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M9.25 0.75H15.25V6.75H9.25V0.75Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M9.25 9.25H15.25V15.25H9.25V9.25Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M0.75 9.25H6.75V15.25H0.75V9.25Z" stroke="currentColor" stroke-width="1.5" />
  </g>
  <defs>
    <clipPath id="clip0_1_8">
      <rect width="16" height="16" />
    </clipPath>
  </defs>
</svg>
