<script>
  import { route } from "ziggy-js"
  import { createEventDispatcher } from "svelte"
  import { router } from "@inertiajs/svelte"
  import Icon from "@components/Icon.svelte"
  import Input from "@components/Input.svelte"
  import auth from "@store/auth"

  export { className as class }
  export let id

  let className = ""
  let keyword = ""

  const dispatch = createEventDispatcher()
  function search(event) {
    router.get(
      event.currentTarget.action,
      { keyword },
      {
        onSuccess: () => {
          dispatch("search")
          document.activeElement.blur()
          keyword = ""
        },
      },
    )
  }

  $: hasSubscription = $auth.merchant.has_active_subscription
</script>

<form
  action={route("merchant.products.index")}
  method="GET"
  class="the-header-search {className}"
  on:submit|preventDefault={search}
>
  <div class="flex items-center relative">
    <div class="h-8 w-8 flex items-center justify-center absolute left-0 pointer-events-none">
      <Icon name="search" weight="line" class="text-neutral-500" />
    </div>

    <Input
      {id}
      type="text"
      size="none"
      class="bg-white/80 text-base py-1.5 pl-7 pr-2"
      placeholder="Search…"
      name="keyword"
      bind:value={keyword}
      disabled={!hasSubscription}
    />

    <button type="submit" class="sr-only">
      <span>Search products</span>
    </button>
  </div>
</form>
