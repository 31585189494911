<script>
  import asset from "@helpers/asset"

  export let src = undefined
  export let height = undefined
  export let width = undefined
  export let quality = undefined
  export let alt = undefined
  export let aspect = "square"
  export let fit = "cover"
  export { className as class }

  let className = ""

  const aspectRatios = {
    square: "c-image--square [ aspect-square ]",
    "16/9": "c-image--16/9 [ aspect-[16/9] ]",
    "9/3": "c-image--9/3 [ aspect-[9/3] ]",
  }

  const fittings = {
    cover: "c-image--cover [ object-cover ]",
    contain: "c-image--contain [ object-contain ]",
  }
</script>

<div class="c-image [ relative overflow-hidden ] {aspectRatios[aspect] || ''} {className}">
  <img
    src={asset(src, { width, height, quality })}
    {alt}
    {width}
    {height}
    loading="lazy"
    class="[ absolute h-full w-full object-center ] {fittings[fit]}"
  />
</div>
