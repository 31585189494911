<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import Dialog from "@objects/Dialog.svelte"
  import DialogBody from "@objects/DialogBody.svelte"
  import Card from "@objects/Card.svelte"
  import { closeAllModals, closeModal } from "svelte-modals"
  import { useForm } from "@inertiajs/svelte"
  import FormField from "@components/FormField.svelte"
  import SubmitButton from "@components/SubmitButton.svelte"
  import Input from "@components/Input.svelte"
  import notifications from "@store/notifications"
  import { onMount } from "svelte"
  import DialogHeader from "@components/dialogs/DialogHeader.svelte"
  import DialogActions from "@components/dialogs/CouponDialog/DialogActions.svelte"
  import Analytics from "@services/Analytics"
  import { collectionsStore } from "@store/collections"
  import Select from "@components/Select.svelte"

  export let isOpen
  export let onCreate

  let inputEl

  const form = useForm({
    name: "",
    collection_type: "",
  })

  async function handleSubmit() {
    try {
      $form.processing = true
      const { data } = await axios.post(route("merchant.collections.store"), $form.data())
      await collectionsStore.refresh()

      Analytics.track("Merchant created collection", {
        collectionId: data.id,
        name: data.name,
      })

      if (onCreate && typeof onCreate === "function") {
        onCreate(data)
      }

      closeModal()
    } catch (e) {
      $form.setError(e.response.data.errors)
      notifications.error({ message: "The collection could not be created." })
    } finally {
      $form.processing = false
    }
  }

  onMount(() => {
    inputEl.focus()
  })
</script>

{#if isOpen}
  <Dialog on:close={closeAllModals}>
    <DialogBody class="shadow-xl md:max-w-2xl w-full">
      <Card size="modal" type="canvas" class="space-y-8">
        <DialogHeader title="Create a new collection" />

        <form on:submit|preventDefault={handleSubmit} class="space-y-8 w-full">
          <FormField label="Collection Name" name="name" error={$form.errors.name}>
            <Input id="name" name="name" type="text" autocomplete="off" bind:this={inputEl} bind:value={$form.name} />
          </FormField>

          <FormField label="Collection Type" name="collection_type" error={$form.errors.collection_type}>
            This will help you organize your collections. You can change this later.
            <Select id="collection_type" name="collection_type" bind:value={$form.collection_type}>
              <option disabled hidden value="">Select a type</option>
              <option value="inspiration">Inspiration</option>
              <option value="client">Client</option>
            </Select>
          </FormField>

          <DialogActions>
            <SubmitButton type="primary" processing={$form.processing}>Create Collection</SubmitButton>
          </DialogActions>
        </form>
      </Card>
    </DialogBody>
  </Dialog>
{/if}
