<script>
  import notifications from "@store/notifications"
  import { createEventDispatcher } from "svelte"
  export let value
  export let label
  export let id = "copy-to-clipboard"
  export let disabled = false

  export let notification = {
    title: "URL copied",
    message: "The URL has been copied to your clipboard",
  }

  const dispatch = createEventDispatcher()
  let input

  function copy() {
    if (disabled) {
      return
    }

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          notifications.success(notification)
          dispatch("copy")
        })
        .catch(() =>
          notifications.error({ title: "Something went wrong", message: "Could not copy the item to your clipboard." }),
        )
    } else {
      input.select()
      document.execCommand("copy")
      notifications.success(notification)
      dispatch("copy")
    }
  }
</script>

<label for={id} class="sr-only" data-testid="clipboard-label">{label}</label>
<input
  class="sr-only"
  type="text"
  {id}
  {value}
  bind:this={input}
  readonly
  tabindex="-1"
  data-testid="clipboard-input"
/>

<slot {copy} />
