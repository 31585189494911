<script>
  import { useForm } from "@inertiajs/svelte"
  import { route } from "ziggy-js"
  import notifications from "@store/notifications"

  export let collection

  const form = useForm({
    collection_type: collection.collection_type ?? "null",
  })

  function updateCollectionType() {
    $form.post(route("merchant.collections.update", collection), {
      preserveScroll: true,
      onSuccess: () => {
        notifications.success({
          message: "Collection type updated successfully.",
        })
      },
    })
  }
</script>

<div class="relative">
  <select
    bind:value={$form.collection_type}
    on:change={updateCollectionType}
    class="opacity-[0.001] bg-red-900 absolute inset-0 peer"
  >
    {#if collection.collection_type == null}
      <option value="" />
    {/if}
    <option value="inspiration">Inspiration</option>
    <option value="client">Client</option>
  </select>

  <div
    class="text-sm font-body bg-none bg-eggplant-950 border-none text-white py-1 px-4 rounded not-sr-only border peer-focus:ring-2"
  >
    {#if collection.collection_type == null}
      Choose collection type…
    {:else}
      <span class="capitalize">{$form.collection_type}</span>
    {/if}
  </div>
</div>
