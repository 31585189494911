<script>
  import { route } from "ziggy-js"
  import axios from "axios"
  import { dndzone } from "svelte-dnd-action"
  import { createEventDispatcher } from "svelte"
  import notifications from "@store/notifications"

  export let selection
  export let images

  $: uploads = []
  let errors = []

  const dispatch = createEventDispatcher()

  function handleChange(event) {
    const uniqueFiles = getUniqueFiles(Array.from(event.target.files)).map(uploadFile)
    uploads = [...uniqueFiles, ...uploads]
  }

  function getUniqueFiles(files) {
    return files.filter((file) => uploads.find((upload) => upload.file.name === file.name) === undefined)
  }

  function uploadFile(file) {
    const formData = new FormData()
    formData.append("file", file)

    let progressPercent = 0

    const request = axios({
      url: route("merchant.selections.images.store", selection),
      method: "POST",
      data: formData,
      onUploadProgress: function (progressEvent) {
        progressPercent = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        let updatedFileIndex = uploads.findIndex((upload) => upload.file.name === file.name)
        uploads[updatedFileIndex] = { file, progressPercent, request }
      },
    })
      .then((response) => {
        images = [...images, response.data.image]
        dispatch("updated", { selection: response.data.selection })
      })
      .catch((error) => {
        errors = error.response.data.errors
      })

    return {
      file,
      progressPercent,
      request,
    }
  }

  function setFeaturedImage(selectedImage) {
    axios({
      url: route("merchant.selections.selection-images.featured-image.update", [selection, selectedImage]),
      method: "PUT",
    }).then((response) => {
      dispatch("updated", { selection: response.data.selection })

      images = images.map((image) => {
        image.is_featured = image.id === selectedImage.id
        return image
      })
    })
  }

  function deleteImage(image) {
    axios({
      url: route("merchant.selections.images.destroy", [selection, image]),
      method: "DELETE",
    }).then((response) => {
      images = images.filter((selectionImage) => selectionImage.id !== image.id)
      dispatch("updated", { selection: response.data.selection })
    })
  }

  function onImageDragConsider({ detail }) {
    images = detail.items
  }

  function onImageDragFinalize({ detail }) {
    const MOVED_OUT_OF_LIST = -1
    const position = images.findIndex((image) => image.id === detail.info.id)

    if (position === MOVED_OUT_OF_LIST) {
      return
    }

    const payload = { images: images.map((image) => image.id) }

    axios
      .post(route("merchant.selections.selection-images.order.store", [selection]), payload)
      .then((response) => {
        images = detail.items
        notifications.success({ message: "Image order updated." })
        dispatch("updated", { selection: response.data.selection })
      })
      .catch((error) => {
        notifications.success({ message: "The image order could not be updated." })
        console.error("Error updating image order", error)
      })
  }
</script>

<form on:submit|preventDefault class="[ relative mt-4 ]">
  {#if errors.length !== 0}
    {#each errors.file as error}
      <p class="[ text-red-500 ]">{error}</p>
    {/each}
  {/if}

  <label
    for="file"
    class:border-red-500={errors.length !== 0}
    class="block border p-12 text-lg text-gray-500 flex justify-center items-center space-x-2 mt-2"
  >
    <i class="[ ri-image-add-fill ]" />
    <span>Upload images</span>
  </label>

  <input
    id="file"
    name="file"
    type="file"
    multiple
    max="3"
    on:change={handleChange}
    class="[ absolute top-0 left-0 w-full h-full opacity-0 ]"
  />
</form>

<div
  use:dndzone={{
    items: images,
    dropTargetClasses: ["ring-4"],
  }}
  on:consider={onImageDragConsider}
  on:finalize={onImageDragFinalize}
  class="[ grid grid-cols-2 md:grid-cols-3 gap-4 mt-4 ring-eggplant-700 ring-offset-4 ]"
>
  {#each images as image (image.id)}
    <div class="[ relative group col-span-1 h-[8rem] ]">
      <form on:submit|preventDefault={() => deleteImage(image)}>
        <button
          type="submit"
          class="[ bg-black bg-opacity-75 absolute top-0 right-0 mt-3 mr-3 text-white h-10 w-10 rounded-full flex items-center justify-center hidden group-hover:block ]"
        >
          <i class="[ ri-close-fill text-2xl ]" />
          <span class="[ sr-only ]">Delete Image</span>
        </button>
      </form>

      <a href={image.url} target="_blank">
        <span class="[ sr-only ]">Click to view image {image.id}</span>
        <div
          class="[ aspect-square h-full ]"
          class:ring-eggplant-700={image.is_featured}
          class:ring-4={image.is_featured}
        >
          <img class="[ object-center object-cover h-full w-full ]" src={image.url} alt={selection.name} />
        </div>
      </a>

      {#if !image.is_featured}
        <div
          class="[ absolute bottom-0 w-full hidden group-hover:block bg-gradient-to-b from-transparent to-[#00000050] ]"
        >
          <form
            on:submit|preventDefault={() => setFeaturedImage(image)}
            class="[ w-full justify-center flex items-center py-4 ]"
          >
            <button
              type="submit"
              class="[ px-3 py-2 text-sm leading-4 inline-flex items-center font-medium rounded border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 [ text-white bg-eggplant-600 hover:bg-eggplant-800 focus:ring-eggplant-700 ] dark:bg-sky-600 dark:font-semibold dark:hover:bg-sky-600 dark:focus:ring-sky-500 dark:ring-offset-slate-900 ]"
            >
              Use as primary image
            </button>
          </form>
        </div>
      {/if}
    </div>
  {/each}
</div>
