const InvisibleCharacter = "\u00a0"

/**
 * Warning: This function dangerously sets the `innerHTML` of the node, and
 * must not be used with user-generated content.
 */
export default function widont(node) {
  const segments = node.innerHTML.split(" ")
  const couple = segments.slice(segments.length - 2).join(InvisibleCharacter)
  const rest = segments.slice(0, segments.length - 2).join(" ")

  node.innerHTML = `${rest} ${couple}`

  return {}
}
